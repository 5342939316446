import { useUIContext } from "../../contexts/UIContext";
import TemplateModal from "./TemplateModal";
import ModalContainer from "./ModalContainer";
import AddWorkspaceModal from "./AddWorkspaceModal";
import AddProjectModal from "./AddProjectModal/AddProjectModal"; // Yeni modal
import WorkspaceMembersModal from "./WorkspaceMembersModal";
import WorkspaceRenameModal from "./WorkspaceRenameModal";
import PublishProjectModal from "./PublishProjectModal";
import ShareProjectModal from "./ShareProjectModal";
import UpdateProjectModal from "./UpdateProjectModal";
import AddMemberToWorkspaceModal from "./AddMemberToWorkspaceModal"; // Yeni import

const Modals = () => {
  const { modalsState } = useUIContext();

  return (
    <>
      {modalsState.isTemplateModalVisible && (
        <ModalContainer>
          <TemplateModal />
        </ModalContainer>
      )}
      {modalsState.isAddWorkspaceModalVisible && (
        <ModalContainer>
          <AddWorkspaceModal />
        </ModalContainer>
      )}
      {modalsState.isAddProjectModalVisible && (
        <ModalContainer>
          <AddProjectModal />
        </ModalContainer>
      )}
      {modalsState.isWorkspaceMembersModalVisible && (
        <ModalContainer>
          <WorkspaceMembersModal />
        </ModalContainer>
      )}
      {modalsState.isRenameWorkspaceModalVisible && (
        <ModalContainer>
          <WorkspaceRenameModal />
        </ModalContainer>
      )}
      {modalsState.isPublishProjectModalVisible && (
        <ModalContainer>
          <PublishProjectModal />
        </ModalContainer>
      )}
      {modalsState.isShareProjectModalVisible && (
        <ModalContainer>
          <ShareProjectModal />
        </ModalContainer>
      )}
      {modalsState.isUpdateProjectModalVisible && (
            <ModalContainer>
            <UpdateProjectModal />
          </ModalContainer>
      )} 
      {modalsState.isAddMemberToWorkspaceModalVisible && (
        <ModalContainer>
          <AddMemberToWorkspaceModal />
        </ModalContainer>
      )}
    </>
  );
};
export default Modals;
