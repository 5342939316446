import { useState, useEffect, useRef } from "react";
import { useUIContext } from "../../contexts/UIContext";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext"; // Context
import { createWorkspace, checkUsername } from "../../api/workspace";
import { searchUsers } from "../../api/users"; // Yeni import
import Icon from "../common/Icon";
import { toast } from "react-toastify";
import { useAuthContext } from "../../contexts/AuthContext";
import { Workspace } from "../../types/ContextTypes";
import SearchableDropdown from '../common/SearchableDropdown';

const AddWorkspaceModal = () => {
  const { hideAllModals } = useUIContext(); // Modal kontrolü için context
  const { username } = useAuthContext();
  const { ownedWorkspaces, setOwnedWorkspaces } = useWorkspaceContext();
  const [workspaceName, setWorkspaceName] = useState<string>(""); // Workspace adı
  const [newMembers, setNewMembers] = useState<
    { username: string; roleId: number }[]
  >([]); // Üyeler
  const [memberUsername, setMemberUsername] = useState<string>(""); // Yeni üye için kullanıcı adı
  const [memberRoleId, setMemberRoleId] = useState<number>(3); // Yeni üye için rol (default: Member)
  const [searchResults, setSearchResults] = useState<{ username: string }[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isSelecting, setIsSelecting] = useState(false);

  // Dışarı tıklanınca tooltip'i kapat
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Debounced search fonksiyonu
  useEffect(() => {
    if (isSelecting) {
      setIsSelecting(false);
      return;
    }

    if (memberUsername.trim() === '') {
      setSearchResults([]);
      setIsSearching(false);
      setShowTooltip(false);
      return;
    }

    setIsSearching(true);
    setShowTooltip(true);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(async () => {
      try {
        const results = await searchUsers(memberUsername, 5);
        const filteredResults = results.filter(user => 
          user.username !== username && 
          !newMembers.some(member => member.username === user.username)
        );
        setSearchResults(filteredResults);
      } catch (error) {
        console.error('Kullanıcı araması başarısız:', error);
        setSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    }, 1000);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [memberUsername, username, newMembers, isSelecting]);

  // Kullanıcı seçme fonksiyonu güncellendi
  const handleSelectUser = (selectedUsername: string) => {
    setIsSelecting(true);
    setShowTooltip(false);
    setMemberUsername(selectedUsername);
    setSearchResults([]);
  };

  // Input focus handler
  const handleInputFocus = () => {
    if (memberUsername.trim() !== '') {
      setShowTooltip(true);
    }
  };

  const handleAddMember = async () => {
    try {
      // Boş kullanıcı adı kontrolü
      if (!memberUsername || memberUsername.trim() === "") {
        toast.error("Kullanıcı adı boş bırakılamaz");
        return;
      }

      // Kullanıcının kendisini eklemesini engelle
      if (memberUsername === username) {
        toast.error("Kendinizi üyelere ekleyemezsiniz");
        return;
      }

      // Kullanıcının zaten eklenmiş olup olmadığını kontrol et
      const isConflict = newMembers.some(
        (member) => member.username === memberUsername
      );
      if (isConflict) {
        toast.error("Bu üye zaten eklendi");
        return;
      }

      // Kullanıcı adını kontrol et (mevcut değilse hata ver)
      const isUsernameValid = await checkUsername(memberUsername);
      if (!isUsernameValid) {
        toast.error("Kullanıcı adı bulunamadı");
        return;
      }

      // Yeni üyeyi listeye ekle
      let tempNewMembers = [...newMembers]; // referans degisikligi
      tempNewMembers.push({ username: memberUsername, roleId: memberRoleId });
      setNewMembers(tempNewMembers);
      toast.success("Üye başarıyla eklendi!");
    } catch (error: any) {
      console.error("Hata oluştu:", error.message);
      toast.error("Üye eklenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const handleRemoveMember = (__username: string) => {
    let updatedNewMembers = newMembers.filter(
      (member) => member.username !== __username
    );
    setNewMembers(updatedNewMembers);
    toast.info(`${__username} kişisi çıkartıldı`);
  };

  const handleCreateWorkspace = async () => {
    try {
      // Çalışma alanı adının boş olup olmadığını kontrol et
      if (!workspaceName || workspaceName.trim() === "") {
        toast.error("Çalışma alanı adı boş bırakılamaz");
        return;
      }

      // createWorkspace çağrısı
      const response = await createWorkspace(workspaceName, newMembers);

      // Yeni çalışma alanı oluşturma
      const newWorkspace: Workspace = {
        workspaceId: response.workspaceId, // Yanıtın workspaceId'si
        workspaceName: workspaceName,
        projectCount: 0,
        workspaceProjects: [],
      };

      // Mevcut ownedWorkspaces'i güncelle
      setOwnedWorkspaces((prevOwnedWorkspaces) => [
        ...prevOwnedWorkspaces,
        newWorkspace,
      ]);

      // Başarı mesajı
      toast.success("Çalışma alanı oluşturuldu");
      hideAllModals()
    } catch (err: any) {
      console.error("Hata oluştu:", err.message);
      toast.error(
        "Çalışma alanı oluşturulurken bir hata oluştu. Lütfen tekrar deneyin."
      );
    }
  };

  return (
    <div className="relative w-96 h-auto pt-8 bg-white rounded-xl">
      <div className="flex justify-between items-center pl-8 pr-4">
        <h2 className="text-gray-400">Yeni Çalışma Alanı Ekle</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 mt-4"></div>
      <div className="p-8">
        <input
          type="text"
          value={workspaceName}
          onChange={(e) => setWorkspaceName(e.target.value)}
          placeholder="Çalışma alanı adı"
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
        <div className="w-full h-0.5 border border-gray-200 my-4"></div>
        <h3 className="text-md  mb-2">Kişi ekle</h3>
        <div className="flex items-center justify-between gap-2 mb-4">
          <SearchableDropdown
            value={memberUsername}
            onChange={setMemberUsername}
            onSelect={(username) => setMemberUsername(username)}
            onSearch={(query) => searchUsers(query, 5)}
            placeholder="Kullanıcı adı"
            className="w-36"
            filterResults={(results) => 
              results.filter(user => 
                user.username !== username && 
                !newMembers.some(member => member.username === user.username)
              )
            }
          />

          <select
            value={memberRoleId}
            onChange={(e) => setMemberRoleId(Number(e.target.value))}
            className="p-2 border border-gray-300 rounded-lg"
          >
            <option value={2}>Admin</option>
            <option value={3}>Üye</option>
          </select>

          <button
            onClick={handleAddMember}
            className="py-2 px-4 bg-blue-400 text-white rounded-lg"
          >
            Ekle
          </button>
        </div>
        <div className="w-full h-0.5 border border-gray-200 my-4"></div>
        <h3 className="text-md mb-2 underline underline-offset-2 font-medium">
          Kişiler
        </h3>

        <div className="mb-8">
          <div className="flex items-center justify-between mt-4">
            <p className="">{username}</p>
            <div className="relative">
              <div className="absolute right-8 top-1/2 -translate-y-1/2 flex justify-center items-center py-1 px-2 rounded-xl bg-orange-500">
                <p className="text-sm text-white">Sahip</p>
              </div>
            </div>
          </div>
          {newMembers.map((member, index) => (
            <div key={index} className="flex items-center justify-between mt-4">
              <p className="">{member.username}</p>
              <div>
                <div className="relative flex items-centergap-2">
                  <div
                    className={`absolute right-8 top-1/2 -translate-y-1/2 flex justify-center items-center py-1 px-2 rounded-xl ${
                      member.roleId === 2 ? "bg-red-500" : "bg-green-500"
                    }`}
                  >
                    <p className="text-sm text-white">
                      {member.roleId === 2 ? "Admin" : "Üye"}
                    </p>
                  </div>
                  <div onClick={() => handleRemoveMember(member.username)}>
                    <Icon
                      name="remove-person-icon"
                      className="text-gray-400 cursor-pointer hover:text-gray-700"
                      size={20}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={handleCreateWorkspace}
          className="w-full py-2 bg-black text-white rounded-lg"
        >
          Oluştur
        </button>
      </div>
    </div>
  );
};

export default AddWorkspaceModal;
