import Icon from "../common/Icon";
import { useSlideContext } from "../../contexts/SlideContext";
import SlideLayout from "./SlideLayout";
import { useUIContext } from "../../contexts/UIContext";
import { updateProject } from "../../api/project";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";

const SlideCanvas = () => {
  const { canvasState, projectPermissions } = useSlideContext();


  // Eğer izinler arasında "edit" yoksa readonly true olur
  const isReadonly = !projectPermissions.includes("edit");

  return (
    <div className="flex-1 flex flex-col justify-center items-center gap-4 h-full relative z-50">
      <Header />

      <div
        className={`hidden-scrollbar ${
          canvasState.screenType === "mobile" ? "w-[420px]" : "w-full"
        } h-[calc(100%-70px)] border bg-white border-gray-200`}
      >
        <SlideLayout readonly={isReadonly} />
      </div>
    </div>
  );
};

export default SlideCanvas;

const Header = () => {
  const { canvasState, toggleScreenType, projectData, autoSave, setAutoSave } =
    useSlideContext();

  const { setIsSaveError, setIsSaving, isSaveError, isSaving } = useUIContext();
  const { projectUuid } = useParams();

  const handlePreview = () => {
    if (!projectData) return;

    window.open(
      `${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData.projectUuid}/preview`,
      "_blank"
    );
  };

  // manuel save

  const saveProject = async () => {
    if (projectData && projectUuid) {
      setIsSaving(true);
      setIsSaveError(false);
      try {
        await updateProject(projectUuid, projectData);
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        setIsSaveError(true);
        toast.error("Proje kaydedilirken sorun olustu!");
      }
    }
  };

  return (
    <header className="flex items-center justify-between w-full h-12 px-6 bg-[#f7f7f6] rounded-xl z-30 relative">
      <div className="flex items-center h-full">
        <div className="flex items-center gap-2 cursor-pointer">
          <Icon name={`${canvasState.contentMode}-mode-icon`} size={20} />
          <span className="font-extralight text-sm capitalize">
            {canvasState.contentMode === "survey" && "form modu"}
            {canvasState.contentMode === "presentation" && "sunum modu"}
          </span>
        </div>
        <div className="h-full w-0.5 bg-[#ededec] mx-4"></div>

        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={toggleScreenType}
        >
          <Icon name={`${canvasState.screenType}-icon`} size={24} />
          <span className="font-extralight text-sm capitalize">
            {canvasState.screenType === "desktop" && "Masaüstü Görünümü"}
            {canvasState.screenType === "mobile" && "Mobile görünümü"}
          </span>
        </div>
        <div className="h-full w-0.5 bg-[#ededec] mx-4"></div>

        <div
          className="flex items-center cursor-pointer"
          onClick={handlePreview} // Önizleme işlevini bağlıyoruz
        >
          <Icon name="preview-icon" size={24} />
          <span className="font-extralight text-sm cursor-pointer">
            Ön İzleme
          </span>
        </div>
      </div>
      <div className="flex items-center h-full">
        <div>
          {/* isSaving durumu: Spinner */}
          {isSaving && <PuffLoader size={20} color={"#3b82f6"} />}

          {/* isSaving false ve isSaveError false: Tick */}
          {!isSaving && !isSaveError && (
            <Icon name="sync-success-icon" size={24} />
          )}

          {/* isSaveError durumu: Çarpı */}
          {!isSaving && isSaveError && <Icon name="sync-fail-icon" size={24} />}
        </div>
        <div className="h-full w-0.5 bg-[#ededec] mx-4"></div>

        <div
          className="flex items-center justify-center gap-1 cursor-pointer h-full"
          onClick={saveProject}
        >
          {/* Kaydet Iconu */}
          <Icon name="save-icon" size={24} />
          <span className="font-extralight text-sm cursor-pointer">Kaydet</span>
        </div>
        <div className="h-full w-0.5 bg-[#ededec] mx-4"></div>

        <div className="flex items-center gap-3 cursor-pointer">
          <span className="font-extralight text-sm cursor-pointer">
            Otomatik Kayıt
          </span>
          <div
            className="w-8 h-1 bg-gray-200 rounded-full"
            onClick={() => setAutoSave((prev) => !prev)}
          >
            <div className="w-full h-full relative">
              <div
                className={`absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full ${
                  autoSave ? "bg-blue-500 right-0" : "bg-gray-500 left-0"
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
