import { useNavigate } from "react-router-dom";
import Button from "../components/common/Button";

const Forbidden = () => {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="flex flex-col items-center">
        <img src="assets/images/access-denied.webp" alt="no access illustration" />
        <div>
          <h1 className="text-2xl text-bold text-gray-400">
            Bu sayfaya erişim yetkiniz yok!
          </h1>
          <div className="flex justify-center mt-6">
            <Button
              label="Ana Sayfaya Dön"
              className="bg-gray-600"
              textClassName="text-white"
              onClick={handleNavigateHome}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
