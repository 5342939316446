import { useState } from "react";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext";
import { useUIContext } from "../../contexts/UIContext";
import Icon from "../common/Icon";
import { toast } from "react-toastify";
import { updateWorkspace } from "../../api/workspace";

const WorkspaceRenameModal = () => {
  const { hideAllModals } = useUIContext(); // Modal kontrolü için context
  const {
    selectedWorkspace,
    ownedWorkspaces,
    memberWorkspaces,
    setOwnedWorkspaces,
    setMemberWorkspaces,
  } = useWorkspaceContext();
  const [newWorkspaceName, setNewWorkspaceName] = useState<string>(
    selectedWorkspace?.workspaceName || ""
  ); // Input için state
  const [isRenaming, setIsRenaming] = useState<boolean>(false); // İşlem durumu için state

  const handleRenameWorkspace = async () => {
    if (!selectedWorkspace) {
      toast.error("Workspace seçilmedi.");
      return;
    }

    if (!newWorkspaceName.trim()) {
      toast.error("Yeni workspace adı boş bırakılamaz.");
      return;
    }

    try {
      setIsRenaming(true);

      // API'ye workspace güncelleme isteği gönder
      await updateWorkspace(selectedWorkspace.workspaceId, newWorkspaceName.trim());

      // `ownedWorkspaces` veya `memberWorkspaces` içindeki workspace'i güncelle
      const updatedOwnedWorkspaces = ownedWorkspaces.map((workspace) =>
        workspace.workspaceId === selectedWorkspace.workspaceId
          ? { ...workspace, workspaceName: newWorkspaceName.trim() }
          : workspace
      );

      const updatedMemberWorkspaces = memberWorkspaces.map((workspace) =>
        workspace.workspaceId === selectedWorkspace.workspaceId
          ? { ...workspace, workspaceName: newWorkspaceName.trim() }
          : workspace
      );

      setOwnedWorkspaces(updatedOwnedWorkspaces);
      setMemberWorkspaces(updatedMemberWorkspaces);

      toast.success("Workspace adı başarıyla güncellendi!");
      hideAllModals();
    } catch (error: any) {
      console.error("Workspace güncellenirken hata oluştu:", error.message);
      toast.error(
        "Workspace güncellenirken bir hata oluştu. Lütfen tekrar deneyin."
      );
    } finally {
      setIsRenaming(false);
    }
  };

  return (
    <div className="relative w-72 h-auto pt-8 bg-white rounded-xl">
      <div className="flex justify-between items-center pl-8 pr-4">
        <h2 className="text-gray-400">Workspace Adını Güncelle</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 mt-4"></div>
      <div className="p-8 pt-4">
        <input
          type="text"
          value={newWorkspaceName}
          onChange={(e) => setNewWorkspaceName(e.target.value)}
          placeholder="Yeni workspace adı"
          className="w-full p-2 border border-gray-300 rounded-lg mb-4"
        />
        <button
          onClick={handleRenameWorkspace}
          disabled={isRenaming}
          className="w-full py-2 bg-black text-white rounded-lg"
        >
          {isRenaming ? "Güncelleniyor..." : "Güncelle"}
        </button>
      </div>
    </div>
  );
};

export default WorkspaceRenameModal;
