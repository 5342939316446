import React, { useState, useRef, useEffect } from "react";
import Icon from "../common/Icon";
import { useSlideContext } from "../../contexts/SlideContext";
import {
  LayoutDropdownMenuProps,
  LayoutContainerProps,
} from "../../types/ComponentsTypes";
import { ProjectData } from "../../types/ContextTypes";
import { useParams } from "react-router-dom";

const LayoutDropdownMenu: React.FC<LayoutDropdownMenuProps> = ({
  type, // "mobile" veya "desktop"
}) => {
  const {
    selectedSlide,
    slides,
    setSlides,
    projectData,
    setProjectData,
    setSelectedSlide,
    setCanvasState,
  } = useSlideContext();
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const layoutOptions =
    type === "mobile"
      ? ["layout1", "layout2", "layout3", "layout4"]
      : ["layout1", "layout2", "layout3", "layout4", "layout5", "layout6"];

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLayoutChange = (layoutName: string) => {
    if (!selectedSlide || !projectData) return;

    setCanvasState((prev) => ({ ...prev, screenType: type }));
    // Slides dizisini güncelle
    const updatedSlides = slides.map((slide) =>
      slide.id === selectedSlide.id
        ? {
            ...slide,
            settings: {
              ...slide.settings,
              layout: {
                ...slide.settings.layout,
                [type]: layoutName,
              },
            },
          }
        : slide
    );

    // updatedProjectData oluştur
    const updatedProjectData: ProjectData = {
      ...projectData, // Mevcut projectData kopyalandı
      data: {
        ...projectData.data, // Mevcut data yapısı korundu
        slides: updatedSlides, // slides güncellendi
      },
    };

    // State'leri güncelle
    setSlides(updatedSlides);
    setProjectData(updatedProjectData);
    setSelectedSlide(
      updatedSlides.find((slide) => slide.id === selectedSlide.id) || null
    );
    setIsDropdownVisible(false); // Dropdown'u kapat
  };

  if (!selectedSlide) {
    return <div>Slide seçilmedi!</div>;
  }

  return (
    <div
      ref={dropdownRef}
      className="relative flex items-center justify-between w-20 h-8 p-3 bg-white border border-gray-200 rounded-xl cursor-pointer"
      onClick={toggleDropdown}
    >
      <div
        className={`flex justify-center items-center ${
          type === "mobile" ? "w-3" : "w-7"
        } h-5 border border-black rounded-sm`}
      >
        <Icon
          name={`${type}-${selectedSlide.settings.layout[type]}-icon`}
          className="w-full h-full text-[#3d3d3c]"
        />
      </div>
      <Icon name="dropdown-icon" size={20} />
      {isDropdownVisible && (
        <div
          className={`absolute -bottom-3 translate-y-full -translate-x-1/2 flex flex-wrap justify-center items-center gap-3 p-5 bg-white border-4 border-gray-200 rounded-xl z-40 ${
            type === "mobile" ? "w-36 h-16" : "w-40 h-24"
          }`}
        >
          {layoutOptions.map((layoutName) => (
            <LayoutContainer
              key={layoutName}
              type={type}
              layoutName={layoutName}
              handleLayoutChange={handleLayoutChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const LayoutContainer: React.FC<LayoutContainerProps> = ({
  type,
  layoutName,
  handleLayoutChange,
}) => {
  const { selectedSlide } = useSlideContext();

  const isSelected = selectedSlide?.settings.layout[type] === layoutName;

  return (
    <div
      onClick={() => handleLayoutChange(layoutName)}
      className={`${
        type === "mobile" ? "w-3 h-5" : "w-7 h-5"
      } border rounded-sm flex items-center justify-center cursor-pointer ${
        isSelected ? "border-black" : "border-gray-300"
      } hover:border-black`}
    >
      <Icon
        name={`${type}-${layoutName}-icon`}
        className="w-full h-full text-[#3d3d3c]"
      />
    </div>
  );
};

export default LayoutDropdownMenu;
