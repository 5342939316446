import { useState, useEffect } from "react";
import { useUIContext } from "../../contexts/UIContext";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext";
import { addMemberToWorkspace, checkUsername } from "../../api/workspace";
import Icon from "../common/Icon";
import { toast } from "react-toastify";
import { MemberWithPermissions, permission } from "../../types/ComponentsTypes";
import SearchableDropdown from '../common/SearchableDropdown';
import { searchUsers } from "../../api/users";

interface ProjectPermission {
  projectName: string;
  permissions: permission[];
}

const AddMemberToWorkspaceModal = () => {
  const { hideAllModals } = useUIContext();
  const { selectedWorkspace, workspaceUsers, setWorkspaceUsers } = useWorkspaceContext();
  
  const [username, setUsername] = useState("");
  const [role, setRole] = useState<"admin" | "member">("member");
  const [projectPermissions, setProjectPermissions] = useState<ProjectPermission[]>([]);

  useEffect(() => {
    if (selectedWorkspace) {
      const initialPermissions = selectedWorkspace.workspaceProjects.map(project => ({
        projectName: project.projectName,
        permissions: role === "admin" ? ["view" as permission] : [] as permission[]
      }));
      setProjectPermissions(initialPermissions);
    }
  }, [role, selectedWorkspace]);

  const isExistingMember = (username: string) => {
    return workspaceUsers.some(user => user.username === username);
  };

  const handleAddMember = async () => {
    try {
      if (!username.trim()) {
        toast.error("Kullanıcı adı boş bırakılamaz");
        return;
      }

      if (isExistingMember(username)) {
        toast.error("Bu kullanıcı zaten workspace üyesi");
        return;
      }

      const isUsernameValid = await checkUsername(username);
      if (!isUsernameValid) {
        toast.error("Kullanıcı bulunamadı");
        return;
      }

      const projects = projectPermissions.map(proj => ({
        projectName: proj.projectName,
        permissions: proj.permissions
      }));

      await addMemberToWorkspace(selectedWorkspace!.workspaceId, {
        username,
        asignedRole: role,
        projects
      });

      setWorkspaceUsers(prev => [...prev, {
        username,
        role,
        permissions: projects
      }]);

      toast.success("Üye başarıyla eklendi");
      hideAllModals();
    } catch (error: any) {
      toast.error(error.message || "Üye eklenirken bir hata oluştu");
    }
  };

  const handlePermissionToggle = (projectName: string, perm: permission) => {
    setProjectPermissions(prev => {
      return prev.map(proj => {
        if (proj.projectName !== projectName) return proj;

        let newPermissions = [...proj.permissions];
        
        if (proj.permissions.includes(perm)) {
          if (role === "admin" && perm === "view") return proj;
          newPermissions = newPermissions.filter(p => p !== perm);
        } else {
          newPermissions = [...newPermissions, perm];
          if ((perm === "edit" || perm === "delete") && !newPermissions.includes("view")) {
            newPermissions.push("view");
          }
        }

        return { ...proj, permissions: newPermissions };
      });
    });
  };

  const handleToggleAll = (projectName: string) => {
    setProjectPermissions(prev => {
      return prev.map(proj => {
        if (proj.projectName !== projectName) return proj;

        const allPermissions: permission[] = ["view", "edit", "delete"];
        const hasAllPermissions = allPermissions.every(p => proj.permissions.includes(p));

        return {
          ...proj,
          permissions: hasAllPermissions ? (role === "admin" ? ["view"] : []) : allPermissions
        };
      });
    });
  };

  const isPermissionActive = (projectName: string, permission: permission): boolean => {
    const projectPerm = projectPermissions.find(p => p.projectName === projectName);
    return projectPerm?.permissions.includes(permission) || false;
  };

  const areAllPermissionsActive = (projectName: string): boolean => {
    const projectPerm = projectPermissions.find(p => p.projectName === projectName);
    const allPermissions: permission[] = ["view", "edit", "delete"];
    return projectPerm ? allPermissions.every(p => projectPerm.permissions.includes(p)) : false;
  };

  return (
    <div className="relative w-[650px] h-auto bg-white rounded-xl pt-8">
      <div className="flex justify-between items-center pl-8 pr-4">
        <h2 className="text-gray-400">Yeni Üye Ekle</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 my-4"></div>
      <div className="px-8 pb-6">
        <div className="flex justify-between items-center gap-12">
          <div className="flex-1">
            <h3 className="text-md mb-2 font-medium underline">Kullanıcı Adı</h3>
            <SearchableDropdown
              value={username}
              onChange={setUsername}
              onSelect={(selectedUsername) => setUsername(selectedUsername)}
              onSearch={(query) => searchUsers(query, 5)}
              placeholder="Kullanıcı adı"
            />
          </div>
          <div className="flex-1">
            <h3 className="text-md mb-2 font-medium underline">Rol</h3>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value as "admin" | "member")}
              className="w-full h-10 p-2 border border-gray-300 rounded-lg"
            >
              <option value="member">Üye</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </div>

        <div className="w-full h-0.5 border border-gray-200 my-4"></div>
        <div className="relative">
          <div className="absolute right-0 flex gap-14">
            <p className="font-medium text-gray-500 text-xs underline">Görüntüleme</p>
            <p className="font-medium text-gray-500 text-xs underline">Düzenleme</p>
            <p className="font-medium text-gray-500 text-xs underline">Silme</p>
            <p className="font-medium text-gray-500 text-xs underline">Hepsi</p>
          </div>
          <h3 className="text-md mb-6 font-medium underline">Projeler</h3>
          <div className="flex flex-col gap-3">
            {selectedWorkspace?.workspaceProjects.map(project => (
              <div key={project.uuid} className="flex items-center justify-between">
                <span className="text-sm">{project.projectName}</span>
                <div className="flex gap-16">
                  {(["view", "edit", "delete"] as permission[]).map((perm) => (
                    <div
                      key={perm}
                      onClick={() => handlePermissionToggle(project.projectName, perm)}
                      className={`w-8 h-1 bg-gray-200 rounded-full ${
                        role === "admin" && perm === "view"
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                    >
                      <div className="w-full h-full relative">
                        <div
                          className={`absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full ${
                            isPermissionActive(project.projectName, perm)
                              ? "bg-blue-500 right-0"
                              : "bg-gray-500 left-0"
                          }`}
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    onClick={() => handleToggleAll(project.projectName)}
                    className={`w-8 h-1 bg-gray-200 rounded-full cursor-pointer`}
                  >
                    <div className="w-full h-full relative">
                      <div
                        className={`absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full ${
                          areAllPermissionsActive(project.projectName)
                            ? "bg-blue-500 right-0"
                            : "bg-gray-500 left-0"
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <button
            onClick={handleAddMember}
            className="w-40 py-2 bg-black text-white rounded-lg"
          >
            Ekle
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMemberToWorkspaceModal; 