import React, { useEffect, useRef, useState } from "react";
import Icon from "../common/Icon";
import { useSlideContext } from "../../contexts/SlideContext";
import TemplateDropdownMenu from "./TemplateDropdownMenu";
import LayoutDropdownMenu from "./LayoutDropdownMenu";
import { removeSlideMedia, uploadSlideMedia } from "../../api/project";

const alignOptions = [
  { icon: "align-left-icon", value: "align-left" },
  { icon: "align-center-icon", value: "align-center" },
  { icon: "align-right-icon", value: "align-right" }
];

const SlideSettingsBar = () => {
  const {
    projectData,
    setProjectData,
    slides,
    selectedSlide,
    setSelectedSlide,
    projectPermissions,
    canvasState,
    toggleImagePreview,
    toggleCodePreview
  } = useSlideContext();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const sizes = [
    { value: "small", label: "K", size: "w-4 h-4" },
    { value: "medium", label: "O", size: "w-5 h-5" },
    { value: "large", label: "B", size: "w-6 h-6" },
  ];

  const languages = [
    { value: "javascript", label: "JavaScript" },
    { value: "typescript", label: "TypeScript" },
    { value: "python", label: "Python" },
    { value: "java", label: "Java" },
    { value: "csharp", label: "C#" },
    { value: "cpp", label: "C++" },
    { value: "ruby", label: "Ruby" },
    { value: "php", label: "PHP" },
    { value: "swift", label: "Swift" },
    { value: "go", label: "Go" },
    { value: "rust", label: "Rust" },
    { value: "kotlin", label: "Kotlin" },
    { value: "sql", label: "SQL" },
    { value: "html", label: "HTML" },
    { value: "css", label: "CSS" },
    { value: "scss", label: "SCSS" },
    { value: "less", label: "LESS" },
    { value: "bash", label: "Bash" },
    { value: "dockerfile", label: "Dockerfile" },
    { value: "yaml", label: "YAML" }
  ];

  const themes = [
    { value: "light", label: "Açık Tema" },
    { value: "dark", label: "Koyu Tema" },
  ];

  const updateSlideSetting = (
    key: "titleSize" | "descSize" | "titleColor" | "descColor",
    value: string
  ) => {
    if (!selectedSlide || !projectData) return;

    const updatedSlides = slides.map((slide) =>
      slide.id === selectedSlide.id
        ? {
            ...slide,
            settings: {
              ...slide.settings,
              design: {
                ...slide.settings.design,
                [key]: value,
              },
            },
          }
        : slide
    );

    const updatedProjectData = {
      ...projectData,
      data: {
        ...projectData.data,
        slides: updatedSlides,
      },
    };

    setProjectData(updatedProjectData);
    setSelectedSlide(
      updatedSlides.find((slide) => slide.id === selectedSlide.id) || null
    );
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !selectedSlide || !projectData) return;

    setIsUploading(true);
    try {
      const uploadedURL = await uploadSlideMedia(
        projectData.projectUuid,
        selectedSlide.id,
        file
      );

      const updatedSlides = slides.map((slide) =>
        slide.id === selectedSlide.id
          ? { 
              ...slide, 
              assetLink: uploadedURL,
            }
          : slide
      );

      const updatedProjectData = {
        ...projectData,
        data: {
          ...projectData.data,
          slides: updatedSlides,
        },
      };

      setProjectData(updatedProjectData);
      setSelectedSlide(updatedSlides.find(slide => slide.id === selectedSlide.id) || null);
      toggleImagePreview(true);
    } catch (error) {
      console.error("Dosya yüklenirken hata oluştu:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileRemove = async () => {
    if (!selectedSlide || !projectData) return;

    try {
      await removeSlideMedia(projectData.projectUuid, selectedSlide.id);

      const updatedSlides = slides.map((slide) =>
        slide.id === selectedSlide.id ? { ...slide, assetLink: "" } : slide
      );

      const updatedProjectData = {
        ...projectData,
        data: {
          ...projectData.data,
          slides: updatedSlides,
        },
      };

      setProjectData(updatedProjectData);
      setSelectedSlide(updatedSlides.find(slide => slide.id === selectedSlide.id) || null);
      toggleImagePreview(false);
    } catch (error) {
      console.error("Dosya kaldırılırken hata oluştu:", error);
    }
  };

  const updateCodeSettings = (
    key: "content" | "language" | "theme",
    value: string
  ) => {
    if (!selectedSlide || !projectData) return;

    const updatedSlides = slides.map((slide) =>
      slide.id === selectedSlide.id
        ? {
            ...slide,
            code: {
              ...slide.code,
              [key]: value,
            },
          }
        : slide
    );

    const updatedProjectData = {
      ...projectData,
      data: {
        ...projectData.data,
        slides: updatedSlides,
      },
    };

    setProjectData(updatedProjectData);
    setSelectedSlide(
      updatedSlides.find((slide) => slide.id === selectedSlide.id) || null
    );
  };

  const defaultCode = {
    content: 'console.log("Hello World!");',
    language: "javascript",
    theme: "light" as "light" | "dark"
  };

  const handleAddCode = () => {
    if (!selectedSlide || !projectData) return;

    const updatedSlides = slides.map((slide) =>
      slide.id === selectedSlide.id
        ? {
            ...slide,
            code: defaultCode
          }
        : slide
    );

    const updatedProjectData = {
      ...projectData,
      data: {
        ...projectData.data,
        slides: updatedSlides,
      },
    };

    setProjectData(updatedProjectData);
    setSelectedSlide(updatedSlides.find(slide => slide.id === selectedSlide.id) || null);
    toggleCodePreview(true);
  };

  const handleRemoveCode = () => {
    updateCodeSettings("content", "");
    toggleCodePreview(false);
  };

  const updateDesignSettings = (key: string, value: string) => {
    if (!selectedSlide || !projectData) return;

    const updatedSlide = {
      ...selectedSlide,
      settings: {
        ...selectedSlide.settings,
        design: {
          ...selectedSlide.settings.design,
          [key]: value
        }
      }
    };

    const updatedSlides = slides.map((slide) =>
      slide.id === selectedSlide.id
        ? updatedSlide
        : slide
    );

    const updatedProjectData = {
      ...projectData,
      data: {
        ...projectData.data,
        slides: updatedSlides,
      },
    };

    setProjectData(updatedProjectData);
    setSelectedSlide(updatedSlide);
  };




  if (!selectedSlide) {
    return <div>Seçilen slide yok!</div>;
  }

  return (
    <aside className="basis-[15%] h-full p-4 pb-8 bg-[#f7f7f6] rounded-xl scrollbar overflow-auto">
      {projectPermissions.includes("edit") && (
        <div>
          <div className="relative" ref={dropdownRef}>
            <div className="flex justify-between items-center w-full h-8 px-4 bg-white border border-gray-200 rounded-md cursor-pointer">
              <div className="flex items-center gap-2">
                <Icon size={20} name={selectedSlide.template.iconName} />
                <span>{selectedSlide.template.iconTitle}</span>
              </div>
            </div>
          </div>

          <SlideSettingsBarSep />

          {/* Resim veya Video Yükleme Alanı */}
          <div className="flex items-center justify-between mb-4">
            <span className="text-sm text-gray-500">Resim veya Video</span>
            <div className="flex items-center gap-2">
              {canvasState.isImagePreviewVisible && selectedSlide.assetLink ? (
                <div onClick={handleFileRemove} className="cursor-pointer">
                  <Icon name="delete-icon" size={20} className="fill-red-500" />
                </div>
              ) : (
                <div
                  onClick={() => fileInputRef.current?.click()}
                  className="cursor-pointer"
                >
                  <Icon name="add-icon" size={20} className="fill-green-500" />
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*,video/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </div>
          </div>

          {isUploading && (
            <p className="text-sm text-gray-500">Yükleniyor...</p>
          )}

          {canvasState.isImagePreviewVisible && selectedSlide.assetLink && (
            <div className="mt-4">
              {selectedSlide.assetLink.endsWith(".mp4") ? (
                <video
                  src={selectedSlide.assetLink}
                  controls
                  className="w-full"
                />
              ) : (
                <img
                  src={selectedSlide.assetLink}
                  alt="Uploaded"
                  className="w-full rounded-md"
                />
              )}
            </div>
          )}

          <SlideSettingsBarSep />

          <div className="flex flex-col">
            <h6 className="text-sm mb-6">Metin Ayarları</h6>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Başlık boyutu</span>
              <div className="flex items-center gap-2">
                {sizes.map((sizeItem, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      updateSlideSetting("titleSize", sizeItem.value)
                    }
                    className={`flex justify-center items-center rounded-full  text-black text-xs shadow-md ${
                      sizeItem.size
                    } ${
                      selectedSlide.settings.design.titleSize === sizeItem.value
                        ? "bg-gray-700 text-white"
                        : ""
                    }`}
                  >
                    {sizeItem.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Açıklama boyutu</span>
              <div className="flex items-center gap-2">
                {sizes.map((sizeItem, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      updateSlideSetting("descSize", sizeItem.value)
                    }
                    className={`flex justify-center items-center ${
                      sizeItem.size
                    } rounded-full text-black text-xs shadow-md ${
                      selectedSlide.settings.design.descSize === sizeItem.value
                        ? "bg-gray-700 text-white"
                        : ""
                    }`}
                  >
                    {sizeItem.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Başlık Rengi</span>
              <input
                type="color"
                value={selectedSlide.settings.design.titleColor}
                onChange={(e) =>
                  updateSlideSetting("titleColor", e.target.value)
                }
                className="w-8 h-8 p-0 border-none cursor-pointer"
              />
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Açıklama Rengi</span>
              <input
                type="color"
                value={selectedSlide.settings.design.descColor}
                onChange={(e) =>
                  updateSlideSetting("descColor", e.target.value)
                }
                className="w-8 h-8 p-0 border-none cursor-pointer"
              />
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Başlık Hizalama</span>
              <div className="flex gap-2">
                {alignOptions.map((option) => (
                  <button
                    key={option.value}
                    onClick={() => updateDesignSettings("titleAlign", option.value)}
                    className={`p-2 rounded ${
                      selectedSlide?.settings.design.titleAlign === option.value
                        ? "bg-blue-100"
                        : "hover:bg-gray-100"
                    }`}
                  >
                    <Icon name={option.icon} size={18} />
                  </button>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Açıklama Hizalama</span>
              <div className="flex gap-2">
                {alignOptions.map((option) => (
                  <button
                    key={option.value}
                    onClick={() => updateDesignSettings("descAlign", option.value)}
                    className={`p-2 rounded ${
                      selectedSlide?.settings.design.descAlign === option.value
                        ? "bg-blue-100"
                        : "hover:bg-gray-100"
                    }`}
                  >
                    <Icon name={option.icon} size={18} />
                  </button>
                ))}
              </div>
            </div>
          </div>

          <SlideSettingsBarSep />
          {(canvasState.isImagePreviewVisible || canvasState.isCodePreviewVisible) && (
            <div className="flex flex-col">
              <h6 className="text-sm mb-6">Sayfa Düzeni</h6>
              <div className="flex items-center justify-between mb-3">
                <span className="text-sm text-gray-500">Mobil</span>
                <LayoutDropdownMenu type="mobile" />
              </div>
              <div className="flex items-center justify-between mb-3">
                <span className="text-sm text-gray-500">Masaüstü</span>
                <LayoutDropdownMenu type="desktop" />
              </div>
              <SlideSettingsBarSep />
            </div>
          )}

          {/* Kod alanı */}
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <Icon name="code-icon" size={18} className="fill-black" />
                <span className="text-sm">Kod Alanı</span>
              </div>
              {canvasState.isCodePreviewVisible ? (
                <div 
                  className="cursor-pointer hover:bg-red-50 p-1.5 rounded-lg transition-colors"
                  onClick={handleRemoveCode}
                >
                  <Icon name="delete-icon" size={20} className="fill-red-500" />
                </div>
              ) : (
                <div 
                  className="w-7 h-7 flex justify-center items-center border border-gray-300 rounded-lg bg-white cursor-pointer hover:bg-gray-50 transition-colors"
                  onClick={handleAddCode}
                >
                  <Icon name="add-icon" size={18} className="fill-black" />
                </div>
              )}
            </div>

            {/* Sadece isCodePreviewVisible'a bağlı olarak göster */}
            {canvasState.isCodePreviewVisible && (
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <span className="text-sm text-gray-500">Mevcut Kod</span>
                  <textarea
                    value={selectedSlide.code.content}
                    onChange={(e) => updateCodeSettings("content", e.target.value)}
                    placeholder="Kod içeriğini girin..."
                    className="w-full p-3 text-sm border border-gray-200 rounded-lg resize-none scrollbar bg-gray-50 focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-blue-300 transition-colors"
                    rows={4}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <span className="text-sm text-gray-500">Seçili Dil</span>
                  <select
                    value={selectedSlide.code.language}
                    onChange={(e) => updateCodeSettings("language", e.target.value)}
                    className="w-full p-2.5 text-sm border border-gray-200 rounded-lg bg-white focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-blue-300 transition-colors"
                  >
                    {languages.map((lang) => (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="text-sm text-gray-500">Seçili Tema</span>
                  <select
                    value={selectedSlide.code.theme}
                    onChange={(e) => updateCodeSettings("theme", e.target.value)}
                    className="w-full p-2.5 text-sm border border-gray-200 rounded-lg bg-white focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-blue-300 transition-colors"
                  >
                    {themes.map((theme) => (
                      <option key={theme.value} value={theme.value}>
                        {theme.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </aside>
  );
};

const SlideSettingsBarSep = () => (
  <div className="w-full h-0.5 bg-gray-200 my-4"></div>
);

export default SlideSettingsBar;
