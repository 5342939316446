// UIContext.tsx
import { createContext, useContext, useState, ReactNode } from "react";
import { ModalsState, UIContextType } from "../types/ContextTypes";

const defaultModalsState: ModalsState = {
  isBlackoutVisible: false,
  isTemplateModalVisible: false,
  isAddWorkspaceModalVisible: false, // Yeni modal için varsayılan değer
  isAddProjectModalVisible: false,
  isWorkspaceMembersModalVisible: false,
  isRenameWorkspaceModalVisible: false,
  isPublishProjectModalVisible: false, // Yeni modal durumu
  isShareProjectModalVisible: false, // Yeni modal durumu
  isUpdateProjectModalVisible: false,
  isAddMemberToWorkspaceModalVisible: false,
};

const UIContext = createContext<UIContextType | null>(null);

export const UIProvider = ({ children }: { children: ReactNode }) => {
  const [modalsState, setModalsState] =
    useState<ModalsState>(defaultModalsState);
  const [isSaving, setIsSaving] = useState<Boolean>(false);
  const [isSaveError,setIsSaveError] = useState<Boolean>(false)

  const showModal = (modalName: keyof ModalsState) => {
    setModalsState((prevState) => ({
      ...prevState,
      [modalName]: true,
      isBlackoutVisible: true, // Blackout'u tüm modallarda otomatik aç
    }));
  };

  const hideAllModals = () => {
    setModalsState(defaultModalsState);
  };

  return (
    <UIContext.Provider
      value={{ modalsState, showModal, hideAllModals, isSaving, setIsSaving,isSaveError,setIsSaveError }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => {
  const context = useContext(UIContext);
  if (!context) {
    throw new Error("useUIContext must be used within a UIProvider");
  }
  return context;
};
