import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nightOwl, coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import Icon from "./Icon";
import { toast } from "react-toastify";

type CodeSnippetProps = {
  code: string; // Gösterilecek kod içeriği
  language: string; // Kod dili (ör. 'swift', 'kotlin', 'dart', 'javascript')
  theme?: "dark" | "light"; // Tema seçimi
};

const CodeSnippet: React.FC<CodeSnippetProps> = ({
  code,
  language,
  theme = "light",
}) => {
  const selectedTheme = theme === "dark" ? nightOwl : coy;

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    toast.info("Kod kopyalandı");
  };
  return (
    <div className={`w-full h-full border-r-4 `}>
      <header
        className={`h-10 flex items-center justify-between px-5 ${
          theme === "dark" ? "bg-[#011627]" : "bg-gray-100"
        }`}
      >
        <img src="/assets/images/three-dot.svg" alt="three dot" />
        <div
          className="cursor-pointer"
          onClick={() => {
            handleCopy();
          }}
        >
          <Icon
            name="copy-icon"
            className={theme === "dark" ? "text-white" : "text-gray-500"}
            size={20}
          />
        </div>
      </header>
      <div
        className={`text-sm w-full h-[calc(100%-40px)] ${
          theme === "dark" ? "border-[#011627]" : "border-gray-100"
        }`}
      >
        <SyntaxHighlighter
          language={language}
          style={selectedTheme}
          showLineNumbers
          wrapLines={false}
          customStyle={{
            width: "100%",
            height: "100%",
            margin: 0, // Varsayılan boşlukları kaldırır
            padding: 0, // Varsayılan padding'i kaldırır
            paddingLeft: "10px",
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeSnippet;
