import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash'; // Eğer lodash kullanmıyorsanız, kendi debounce fonksiyonunuzu yazabilirsiniz

interface SearchableDropdownProps {
  value: string;
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  onSearch: (query: string) => Promise<any[]>;
  placeholder?: string;
  className?: string;
  debounceTime?: number;
  renderItem?: (item: any) => React.ReactNode;
  filterResults?: (results: any[]) => any[];
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({  
  value,
  onChange,
  onSelect,
  onSearch,
  placeholder = '',
  className = '',
  debounceTime = 1000,
  renderItem,
  filterResults,
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Dışarı tıklanınca dropdown'ı kapat
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Debounced search
  const debouncedSearch = React.useCallback(
    debounce(async (query: string) => {
      if (!query.trim()) {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }

      try {
        setIsSearching(true);
        const results = await onSearch(query);
        console.log('Search results:', results);
        setSearchResults(results); // Direkt API sonuçlarını kullan
      } catch (error) {
        console.error('Search failed:', error);
        setSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    }, debounceTime),
    [onSearch]  // filterResults'ı dependency'den kaldır
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(newValue);
    
    if (newValue.trim()) {
      setIsSearching(true);
      setShowDropdown(true);
      debouncedSearch(newValue);
    } else {
      setShowDropdown(false);
      setSearchResults([]);
    }
  };

  const handleSelect = (item: any) => {
    onSelect(item.username);
    setShowDropdown(false);
    setSearchResults([]);
  };

  const handleInputFocus = () => {
    if (value.trim()) {
      setShowDropdown(true);
    }
  };

  return (
    <div ref={containerRef} className={`relative ${className}`}>
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        placeholder={placeholder}
        className="w-full p-2 border border-gray-300 rounded-lg"
      />
      
      {showDropdown && (isSearching || searchResults.length > 0) && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-48 overflow-y-auto">
          {isSearching ? (
            <div className="p-2 text-gray-500 text-center">Aranıyor...</div>
          ) : searchResults.length > 0 ? (
            searchResults.map((item, index) => (
              <div
                key={index}
                onClick={() => handleSelect(item)}
                className="p-2 hover:bg-gray-100 cursor-pointer"
              >
                {renderItem ? renderItem(item) : item.username}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500 text-center">
              Sonuç bulunamadı
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown; 