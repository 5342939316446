import React from "react";
import Button from "../components/common/Button";
import { useNavigate } from "react-router-dom";

const LoginRequired = () => {
  const navigate = useNavigate();

  const handleNavigateLogin = () => {
    navigate("/auth");
  };

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="flex flex-col items-center">
        <img src="assets/images/login-required.avif" alt="login required illustration" />
        <div>
          <h1 className="-mt-16 text-2xl text-bold text-gray-400">
            Devam etmek için giriş yapın!
          </h1>
          <div className="flex justify-center mt-6">
            <Button
              label="Giriş Sayfasına Git"
              className="bg-gray-600"
              textClassName="text-white"
              onClick={handleNavigateLogin}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRequired;
