import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../common/Icon";
import { toast } from "react-toastify";
import { ProjectCardProps } from "../../types/ComponentsTypes";
import { deleteProject } from "../../api/project";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext";
import { useUIContext } from "../../contexts/UIContext";

type ProjectType = "survey" | "presentation";

const typeTranslations: Record<ProjectType, string> = {
  survey: "Anket",
  presentation: "Sunum",
};

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const {
    selectedWorkspace,
    ownedWorkspaces,
    memberWorkspaces,
    setOwnedWorkspaces,
    setMemberWorkspaces,
    setSelectedProject
  } = useWorkspaceContext();
  const navigate = useNavigate();
  const {showModal} = useUIContext()

  // Proje detaylarına yönlendirme
  const handleNavigate = () => {
    navigate(`/projects/${project.uuid}/edit`);
  };

  // Linki panoya kopyala
  const handleCopyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link);
    toast.info("Link kopyalandı!");
  };

  // Tarih formatını sadeleştirme
  const formatDate = (date: Date) =>
    new Date(date).toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }) +
    " " +
    new Date(date).toLocaleTimeString("tr-TR", {
      hour: "2-digit",
      minute: "2-digit",
    });

  const handleDeleteProject = async (e: React.MouseEvent<HTMLDivElement>) => {
    try {
      e.stopPropagation();

      // Projeyi API üzerinden sil
      await deleteProject(project.uuid);
      toast.success("Proje başarıyla silindi");

      // Workspace'i bul ve projeyi çıkart
      const updatedOwnedWorkspaces = ownedWorkspaces.map((workspace) => {
        if (workspace.workspaceId === selectedWorkspace?.workspaceId) {
          return {
            ...workspace,
            projectCount: workspace.projectCount - 1,
            workspaceProjects: workspace.workspaceProjects.filter(
              (proj) => proj.uuid !== project.uuid
            ),
          };
        }
        return workspace;
      });

      const updatedMemberWorkspaces = memberWorkspaces.map((workspace) => {
        if (workspace.workspaceId === selectedWorkspace?.workspaceId) {
          return {
            ...workspace,
            projectCount: workspace.projectCount - 1,
            workspaceProjects: workspace.workspaceProjects.filter(
              (proj) => proj.uuid !== project.uuid
            ),
          };
        }
        return workspace;
      });

      // UI'ı güncelle
      setOwnedWorkspaces(updatedOwnedWorkspaces);
      setMemberWorkspaces(updatedMemberWorkspaces);
    } catch (err) {
      toast.error("Proje silinirken hata oluştu");
      console.error("Proje silme hatası:", err);
    }
  };

  const handleEditProject = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    showModal("isUpdateProjectModalVisible")
    setSelectedProject(project)
  };

  return (
    <div
      className="p-6 bg-white rounded-2xl shadow-md flex flex-col gap-4 cursor-pointer hover:shadow-lg transition-shadow"
      onClick={handleNavigate}
    >
      {/* header*/}
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-bold text-black">{project.projectName}</h2>
        <div className="flex items-center gap-2">
          <div onClick={handleEditProject}>
            <Icon
              name="edit-icon"
              className="text-gray-500 hover:text-gray-800"
            />
          </div>
          <div onClick={handleDeleteProject}>
            <Icon
              name="delete-icon"
              className="text-gray-500 hover:text-gray-800"
            />
          </div>
        </div>
      </div>

      {/* Detaylar */}
      <div className="flex flex-col gap-2 text-sm">
        <div className="flex items-center">
          <span className="font-medium text-gray-600 w-36">Oluşturulma:</span>
          <span className="text-gray-700">{formatDate(project.createdAt)}</span>
        </div>
        <div className="flex items-center">
          <span className="font-medium text-gray-600 w-36">Güncellenme:</span>
          <span className="text-gray-700">{formatDate(project.updatedAt)}</span>
        </div>
        <div className="flex items-center">
          <span className="font-medium text-gray-600 w-36">Proje Türü:</span>
          <span className="text-gray-700">
            {typeTranslations[project.type]}
          </span>
        </div>
        <div className="flex items-center">
          <span className="font-medium text-gray-600 w-36">Yayın Durumu:</span>
          <span
            className={`px-2 py-1 rounded-full text-xs font-semibold ${
              project.isPublished
                ? "bg-green-100 text-green-600"
                : "bg-red-100 text-red-600"
            }`}
          >
            {project.isPublished ? "Yayında" : "Yayında Değil"}
          </span>
        </div>
        <div className="flex items-center">
          <span className="font-medium text-gray-600 w-36">Yayın Linki:</span>
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleCopyToClipboard(
                `${process.env.REACT_APP_DOMAIN_URL}/projects/${project.uuid}/publish`
              );
            }}
            className="flex items-center gap-2 text-blue-500 cursor-pointer hover:text-blue-700"
          >
            <span>Linki Kopyala</span>
            <Icon
              name="copy-icon"
              size={16}
              className="fill-blue-500 hover:fill-blue-700"
            />
          </div>
        </div>
        <div className="flex items-center">
          <span className="font-medium text-gray-600 w-36">Oluşturan:</span>
          <span className="text-gray-700">{project.createdBy}</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
