import React from "react";
import Icon from "../common/Icon";

interface OrderIndicatorProps {
  order: number;
  isVisible?: boolean;
}

const OrderIndicator: React.FC<OrderIndicatorProps> = ({ 
  order, 
  isVisible = true 
}) => {
  if (!isVisible) return null;

  return (
    <div className="flex items-center gap-1 absolute top-10 left-6 bg-white py-1 px-2 rounded-full text-blue-600">
      <span className="font-bold">{order}</span>
      <Icon name="arrow-forward-icon" />
    </div>
  );
};

export default OrderIndicator; 