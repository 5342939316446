import { useEffect, useState } from "react";
import Icon from "../common/Icon";
import ProjectCard from "./ProjectCard";
import Button from "../common/Button";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext";
import { useUIContext } from "../../contexts/UIContext";
import { toast } from "react-toastify";
import { deleteWorkspace } from "../../api/workspace";
import { WorkspaceProject } from "../../types/ContextTypes";

const HomeWorkspacesContent = () => {
  const {
    selectedWorkspace,
    setSelectedWorkspace,
    setOwnedWorkspaces,
    setMemberWorkspaces,
  } = useWorkspaceContext(); // Workspace context
  const { showModal } = useUIContext(); // Modal kontrolü
  const [projects, setProjects] = useState<WorkspaceProject[]>([]); // Projeler
  const [loading, setLoading] = useState<boolean>(true); // Yükleme durumu
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipVisible = () => {
    setTooltipVisible((prev) => !prev);
  };

  useEffect(() => {
    if (selectedWorkspace) {
      // Projeleri workspace'ten çek
      setProjects(selectedWorkspace.workspaceProjects);
      setLoading(false);
    } else {
      setProjects([]);
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tooltipButton = document.getElementById('workspace-tooltip-button');
      const tooltipMenu = document.getElementById('workspace-tooltip-menu');
      
      if (tooltipVisible && 
          tooltipButton && 
          tooltipMenu && 
          !tooltipButton.contains(event.target as Node) && 
          !tooltipMenu.contains(event.target as Node)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [tooltipVisible]);

  const handleDeleteWorkspace = async () => {
    if (!selectedWorkspace) {
      toast.error("Silinecek bir çalışma alanı seçilmedi.");
      return;
    }

    try {
      // Silme işlemini başlat
      await deleteWorkspace(selectedWorkspace.workspaceId);

      // Toast mesajı
      toast.success("Çalışma alanı başarıyla silindi.");

      // Silinen workspace'i owned veya member listelerinden çıkar
      setOwnedWorkspaces((prev) =>
        prev.filter((workspace) => workspace.workspaceId !== selectedWorkspace.workspaceId)
      );
      setMemberWorkspaces((prev) =>
        prev.filter((workspace) => workspace.workspaceId !== selectedWorkspace.workspaceId)
      );

      // Selected workspace'i sıfırla
      setSelectedWorkspace(null);
    } catch (error: any) {
      console.error("Hata oluştu:", error.message);
      toast.error("Çalışma alanı silinirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  if (!selectedWorkspace) {
    return (
      <div className="flex-1 h-full flex justify-center items-center text-gray-500">
        Bir çalışma alanı seçin.
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex-1 h-full flex justify-center items-center text-gray-500">
        Yükleniyor...
      </div>
    );
  }

  return (
    <div className="flex-1 h-full flex flex-col p-12 bg-[#f7f7f6] rounded-xl">
      <header>
        <div className="flex items-center gap-4">
          <h1 className="text-2xl text-black">{selectedWorkspace.workspaceName}</h1>
          <div 
            id="workspace-tooltip-button"
            className="relative" 
            onClick={handleTooltipVisible}
          >
            {tooltipVisible && (
              <div 
                id="workspace-tooltip-menu"
                className="w-36 flex flex-col absolute left-0 top-6 bg-white z-10 rounded-xl shadow-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <p
                  className="text-sm text-gray-600 px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => showModal("isWorkspaceMembersModalVisible")}
                >
                  Üyeleri görüntüle
                </p>
                <p
                  className="text-sm px-4 text-gray-600 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => showModal("isRenameWorkspaceModalVisible")}
                >
                  Yeniden adlandır
                </p>
                <p
                  className="text-sm px-4 text-gray-600 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={handleDeleteWorkspace}
                >
                  Sil
                </p>
              </div>
            )}
            <Icon
              name="more-horizontal-icon"
              className="fill-gray-500 cursor-pointer"
              size={18}
            />
          </div>
          <div
            className="flex items-start gap-1 cursor-pointer"
            onClick={() => showModal("isAddMemberToWorkspaceModalVisible")}
          >
            <Icon name="add-person-icon" className="fill-gray-500" size={18} />
            <span className="text-sm text-gray-500">Üye ekle</span>
          </div>
        </div>
      </header>
      <div className="w-full h-0.5 my-4 border border-gray-200"></div>
      <div className="w-full h-full">
        {projects.length > 0 ? (
          <div className="grid grid-cols-4 gap-6">
            {projects.map((project) => (
              <ProjectCard key={project.uuid} project={project} />
            ))}
          </div>
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <div className="flex flex-col justify-between items-center w-72 h-44">
              <img
                src="assets/images/no-item.png"
                alt="no item"
                className="w-36"
              />
              <p className="text-xl text-black text-center">
                Bu çalışma alanında proje yok
              </p>
              <Button
                label="Yeni proje"
                iconName="add-icon"
                textClassName="text-white"
                className="bg-black w-36 h-8"
                iconClassName="fill-white"
                onClick={() => showModal("isAddProjectModalVisible")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeWorkspacesContent;
