import React, { useState, useEffect, CSSProperties, useRef } from "react";
import { useSlideContext } from "../../contexts/SlideContext";
import { CanvasScreenType } from "../../types/ContextTypes";
import { SlideLayoutProps } from "../../types/ComponentsTypes";
import CodeSnippet from "../common/CodeSnippet";
import OrderIndicator from "./OrderIndicator";

const getTextAlignment = (align?: string) => {
  switch (align) {
    case "align-left":
      return "left";
    case "align-right":
      return "right";
    case "align-center":
      return "center";
    default:
      return "center";
  }
};

const SlideLayout: React.FC<SlideLayoutProps> = ({
  readonly,
  isPlaceholderVisible = true,
}) => {
  const { selectedSlide, setSlides, setProjectData, projectData, canvasState } =
    useSlideContext();

  // isPlaceHolder gibi bir degisken ile bu degisken ile placeHolderin off durumunu devre disi birakabiliriz

  const [tempTitle, setTempTitle] = useState("");
  const [tempDesc, setTempDesc] = useState("");

  // Debounce için referans
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  // Referanslar
  const titleRef = useRef<HTMLTextAreaElement | null>(null);
  const descRef = useRef<HTMLTextAreaElement | null>(null);

  // Seçilen slayt değiştiğinde geçici başlık, açıklama ve height değerlerini güncelle
  useEffect(() => {
    if (selectedSlide) {
      setTempTitle(selectedSlide.template.templateContent.title || "");
      setTempDesc(selectedSlide.template.templateContent.desc || "");

      // setState'in hemen etkisini göremeyeceğimiz için DOM'u güncellemek için bir sonraki render'ı bekliyoruz.
      setTimeout(() => {
        resizeTextarea(titleRef.current);
        resizeTextarea(descRef.current);
      }, 0);
    }
  }, [selectedSlide, canvasState]);

  // Textarea yüksekliğini ayarlayan yardımcı fonksiyon
  const resizeTextarea = (textarea: HTMLTextAreaElement | null) => {
    if (textarea) {
      textarea.style.height = "auto"; // Mevcut height'i sıfırla
      textarea.style.height = `${textarea.scrollHeight}px`; // İçeriğe göre yeni height belirle
    }
  };

  // Eğer projectData veya selectedSlide mevcut değilse erken dönüş yap
  if (!selectedSlide || !projectData) {
    return <div>Slide seçilmedi veya proje verisi eksik!</div>;
  }

  const { settings } = selectedSlide;
  const { design, layout } = settings;
  const { screenType } = canvasState;

  // Başlık ve açıklama boyutları için eşlemeler
  type Size = "large" | "medium" | "small";

  // Title için
  const titleSizeMap: Record<CanvasScreenType, Record<Size, string>> = {
    desktop: {
      large: "40px",
      medium: "24px",
      small: "16px",
    },
    mobile: {
      large: "32px",
      medium: "18px",
      small: "14px",
    },
  };

  // Description (desc) için
  const descSizeMap: Record<CanvasScreenType, Record<Size, string>> = {
    desktop: {
      large: "32px",
      medium: "18px",
      small: "12px",
    },
    mobile: {
      large: "24px",
      medium: "16px",
      small: "10px",
    },
  };

  // Başlık stili
  const titleStyle: CSSProperties = {
    fontSize: titleSizeMap[canvasState.screenType][design.titleSize],
    color: design.titleColor,
    fontWeight: "bold",
    textAlign: getTextAlignment(design.titleAlign),
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    width: "100%",
    height: "auto",
    resize: "none",
    padding: 0,
    overflow: "hidden",
  };

  // Açıklama stili
  const descStyle: CSSProperties = {
    fontSize: descSizeMap[canvasState.screenType][design.descSize],
    color: design.descColor,
    textAlign: getTextAlignment(design.descAlign),
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    width: "100%",
    height: "auto",
    resize: "none",
    padding: 0,
    overflow: "hidden",
  };

  // ProjectData'yı güncelleyen yardımcı fonksiyon
  const updateProjectData = (key: "title" | "desc", value: string) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current); // Mevcut zamanlayıcıyı temizle
    }

    debounceRef.current = setTimeout(() => {
      const updatedSlides = projectData.data.slides.map((slide) =>
        slide.id === selectedSlide.id
          ? {
              ...slide,
              template: {
                ...slide.template,
                templateContent: {
                  ...slide.template.templateContent,
                  [key]: value,
                },
              },
            }
          : slide
      );

      const updatedProjectData = {
        ...projectData,
        data: {
          ...projectData.data,
          slides: updatedSlides,
        },
      };

      setSlides(updatedSlides); // Slaytları güncelle
      setProjectData(updatedProjectData); // Proje verisini güncelle
    }, 300); // 0.3 saniye debounce
  };

  // Başlık veya açıklama değişimlerinde çağrılan fonksiyon
  const handleChange = (key: "title" | "desc", value: string) => {
    if (key === "title") {
      setTempTitle(value);
    } else {
      setTempDesc(value);
    }

    updateProjectData(key, value);
  };



  // Medya veya kod içeriğini render eden fonksiyon
  const renderMedia = () => {
    // Kod varsa onu göster
    if (canvasState.isCodePreviewVisible) {
      return (
          <CodeSnippet
            code={selectedSlide.code.content}
            language={selectedSlide.code.language}
            theme={selectedSlide.code.theme as "light" | "dark"}
          />
      );
    }

    // Medya varsa onu göster
    if (selectedSlide.assetLink) {
      return selectedSlide.assetLink.endsWith(".mp4") ? (
        <video
          src={selectedSlide.assetLink}
          controls
          className="w-full h-full rounded-md object-contain object-top"
        />
      ) : (
        <img
          src={selectedSlide.assetLink}
          alt="Slide Media"
          className="w-full h-full rounded-md object-contain object-top"
        />
      );
    }

    return null;
  };

  // görselin ve kodun olmadığı durum
  if (selectedSlide.assetLink === "" && !canvasState.isCodePreviewVisible) {
    return (
      <div className="w-full min-h-full h-auto flex justify-center items-center py-12 relative">
        <div className="flex flex-col items-center justify-center w-2/3 h-full gap-2">
          <OrderIndicator
            order={selectedSlide.order}
            isVisible={canvasState.isVisibleOrderIndicator}
          />
          <textarea
            ref={titleRef}
            value={tempTitle}
            placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
            style={titleStyle}
            onChange={(e) => {
              handleChange("title", e.target.value);
              e.target.style.height = "auto";
              e.target.style.height = e.target.scrollHeight + "px";
            }}
            readOnly={readonly}
          />
          <textarea
            ref={descRef}
            value={tempDesc}
            placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
            style={descStyle}
            onChange={(e) => {
              handleChange("desc", e.target.value);
              e.target.style.height = "auto";
              e.target.style.height = e.target.scrollHeight + "px";
            }}
            readOnly={readonly}
          />
        </div>
      </div>
    );
  }

  if (screenType === "mobile") {
    switch (layout.mobile) {
      case "layout1":
        return (
          <div className=" w-full min-h-full h-auto flex px-8 py-16 relative">
            <div className="flex flex-col w-full h-full gap-2">
              <OrderIndicator
                order={selectedSlide.order}
                isVisible={canvasState.isVisibleOrderIndicator}
              />
              <textarea
                ref={titleRef}
                value={tempTitle}
                placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                style={titleStyle}
                onChange={(e) => {
                  handleChange("title", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
              <textarea
                ref={descRef}
                value={tempDesc}
                placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                style={descStyle}
                onChange={(e) => {
                  handleChange("desc", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />

              <div className="w-full h-72">{renderMedia()}</div>
            </div>
          </div>
        );
      case "layout2":
        return (
          <div className="w-full min-h-full h-auto flex flex-col px-8 py-10 relative">
            <div className="w-full h-72 mb-10">{renderMedia()}</div>
            <div className="flex flex-col w-full h-full gap-2 ">
              <OrderIndicator
                order={selectedSlide.order}
                isVisible={canvasState.isVisibleOrderIndicator}
              />
              <textarea
                ref={titleRef}
                value={tempTitle}
                placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                style={titleStyle}
                onChange={(e) => {
                  handleChange("title", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
              <textarea
                ref={descRef}
                value={tempDesc}
                placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                style={descStyle}
                onChange={(e) => {
                  handleChange("desc", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      case "layout3":
        return (
          <div className="w-full min-h-full h-auto flex flex-col relative pb-10">
            <div className="w-full h-72 mb-10">{renderMedia()}</div>
            <div className="flex flex-col w-full h-full gap-2 px-10">
              <OrderIndicator
                order={selectedSlide.order}
                isVisible={canvasState.isVisibleOrderIndicator}
              />
              <textarea
                ref={titleRef}
                value={tempTitle}
                placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                style={titleStyle}
                onChange={(e) => {
                  handleChange("title", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
              <textarea
                ref={descRef}
                value={tempDesc}
                placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                style={descStyle}
                onChange={(e) => {
                  handleChange("desc", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      case "layout4":
        return (
          <div className="relative min-h-full h-auto flex items-center justify-center w-full bg-cover bg-center px-8 py-12">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full">
              {renderMedia()}
            </div>
            <OrderIndicator
              order={selectedSlide.order}
              isVisible={canvasState.isVisibleOrderIndicator}
            />
            {/* Başlık ve açıklama alanları */}
            <div className="text-center relative">
              <textarea
                ref={titleRef}
                value={tempTitle}
                placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                style={titleStyle}
                onChange={(e) => {
                  handleChange("title", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
              <textarea
                ref={descRef}
                value={tempDesc}
                placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                style={descStyle}
                onChange={(e) => {
                  handleChange("desc", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      default:
        return <div>Geçersiz mobile layout</div>;
    }
  }

  if (screenType === "desktop") {
    switch (layout.desktop) {
      case "layout1":
        return (
          <div className="w-full min-h-full h-auto flex justify-center items-center py-12 relative">
            <div className="flex flex-col w-2/3 h-full gap-2">
              <OrderIndicator
                order={selectedSlide.order}
                isVisible={canvasState.isVisibleOrderIndicator}
              />
              <textarea
                ref={titleRef}
                value={tempTitle}
                placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                style={titleStyle}
                onChange={(e) => {
                  handleChange("title", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
              <textarea
                ref={descRef}
                value={tempDesc}
                placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                style={descStyle}
                onChange={(e) => {
                  handleChange("desc", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
              <div className="w-full h-96">{renderMedia()}</div>
            </div>
          </div>
        );
      case "layout2":
        return (
          <div className="w-full min-h-full h-auto flex justify-center items-center relative py-12">
            <div className="flex items-center justify-between gap-12 w-full h-full px-20 py-10">
              <div className="flex flex-1 flex-col h-full w-1/2 gap-2">
                <OrderIndicator
                  order={selectedSlide.order}
                  isVisible={canvasState.isVisibleOrderIndicator}
                />
                <textarea
                  ref={titleRef}
                  value={tempTitle}
                  placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                  style={titleStyle}
                  onChange={(e) => {
                    handleChange("title", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
                <textarea
                  ref={descRef}
                  value={tempDesc}
                  placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                  style={descStyle}
                  onChange={(e) => {
                    handleChange("desc", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
              </div>
              <div className="flex-1 max-w-[600px] h-96">{renderMedia()}</div>
            </div>
          </div>
        );
      case "layout3":
        return (
          <div className="w-full min-h-full h-auto flex justify-center items-center relative py-12">
            <div className="flex flex-row-reverse items-center justify-between gap-24 w-full h-full px-20 py-10">
              <div className="flex flex-1 flex-col h-full w-1/2 gap-2">
                <OrderIndicator
                  order={selectedSlide.order}
                  isVisible={canvasState.isVisibleOrderIndicator}
                />
                <textarea
                  ref={titleRef}
                  value={tempTitle}
                  placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                  style={titleStyle}
                  onChange={(e) => {
                    handleChange("title", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
                <textarea
                  ref={descRef}
                  value={tempDesc}
                  placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                  style={descStyle}
                  onChange={(e) => {
                    handleChange("desc", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
              </div>
              <div className="flex-1 max-w-[600px]  h-96">{renderMedia()}</div>
            </div>
          </div>
        );
      case "layout4":
        return (
          <div className="w-full min-h-full h-auto flex justify-center items-center relative py-12">
            <div className="flex items-center justify-between gap-20 pl-20 w-full h-full">
              <div className="flex flex-1 flex-col h-full w-1/2 gap-2">
                <OrderIndicator
                  order={selectedSlide.order}
                  isVisible={canvasState.isVisibleOrderIndicator}
                />
                <textarea
                  ref={titleRef}
                  value={tempTitle}
                  placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                  style={titleStyle}
                  onChange={(e) => {
                    handleChange("title", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
                <textarea
                  ref={descRef}
                  value={tempDesc}
                  placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                  style={descStyle}
                  onChange={(e) => {
                    handleChange("desc", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
              </div>
              <div className="flex-1 max-w-[700px] h-full">{renderMedia()}</div>
            </div>
          </div>
        );
      case "layout5":
        return (
          <div className="w-full min-h-full h-auto flex justify-center items-center relative py-12">
            <div className="flex flex-row-reverse items-center justify-between gap-20 pr-20 w-full h-full">
              <div className="flex flex-1 flex-col h-full w-1/2 gap-2">
                <OrderIndicator
                  order={selectedSlide.order}
                  isVisible={canvasState.isVisibleOrderIndicator}
                />
                <textarea
                  ref={titleRef}
                  value={tempTitle}
                  placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                  style={titleStyle}
                  onChange={(e) => {
                    handleChange("title", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
                <textarea
                  ref={descRef}
                  value={tempDesc}
                  placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                  style={descStyle}
                  onChange={(e) => {
                    handleChange("desc", e.target.value);
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  readOnly={readonly}
                />
              </div>
              <div className="flex-1 max-w-[700px] h-full">{renderMedia()}</div>
            </div>
          </div>
        );
      case "layout6":
        return (
          <div className="relative min-h-full h-auto w-full flex justify-center items-center bg-cover bg-center py-12">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full">
              {renderMedia()}
            </div>
            <OrderIndicator
              order={selectedSlide.order}
              isVisible={canvasState.isVisibleOrderIndicator}
            />
            <div className="flex flex-col items-center w-2/3 h-full gap-2 relative">
              <textarea
                ref={titleRef}
                value={tempTitle}
                placeholder={isPlaceholderVisible ? "Başlık giriniz" : ""}
                style={titleStyle}
                onChange={(e) => {
                  handleChange("title", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
              <textarea
                ref={descRef}
                value={tempDesc}
                placeholder={isPlaceholderVisible ? "Açıklama giriniz" : ""}
                style={descStyle}
                onChange={(e) => {
                  handleChange("desc", e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = e.target.scrollHeight + "px";
                }}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      default:
        return <div>Geçersiz desktop layout</div>;
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-full gap-4">
      Geçersiz ekran tipi
    </div>
  );
};

export default SlideLayout;
