import { useSlideContext } from "../../contexts/SlideContext";
import { useUIContext } from "../../contexts/UIContext";
import Icon from "../common/Icon";
import { toast } from "react-toastify";
import { updateProject } from "../../api/project";

const PublishProjectModal = () => {
  const { projectData, setProjectData } = useSlideContext();
  const { hideAllModals } = useUIContext();

  // Projeyi yayına alma/kaldırma işlevi
  const togglePublishStatus = async () => {
    if (!projectData) return;

    const updatedProjectData = {
      ...projectData,
      isPublished: !projectData.isPublished,
    };

    // Önce lokal state'i güncelle
    setProjectData(updatedProjectData);

    // Kullanıcıya bilgi mesajı göster
    if (updatedProjectData.isPublished) {
      toast.success("Proje başarıyla yayına alındı.");
    } else {
      toast.info("Proje yayından kaldırıldı.");
    }

    // Backend'e güncellenen durumu gönder
    try {
      await updateProject(projectData.projectUuid, updatedProjectData);
    } catch (error) {
      console.error("Projeyi yayınlama durumu güncellenemedi:", error);
      toast.error("Proje yayınlama durumu güncellenirken bir hata oluştu.");
      // Eğer backend hata verirse state'i geri al
      setProjectData(projectData);
    }
  };

  const handleCopyToClipboard = () => {
    if (projectData) {
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData.projectUuid}/publish`
      );
      toast.info("Link kopyalandı!");
    }
  };

  const handleNavigateToLink = () => {
    if (projectData) {
      window.open(
        `${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData.projectUuid}/publish`,
        "_blank"
      );
    }
  };

  return (
    <div className="relative w-96 bg-white rounded-xl p-6">
      {/* Başlık ve Kapatma İkonu */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium text-gray-800">Proje Ayarları</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 my-4"></div>

      {/* Yayın Durumu */}
      <div className="mb-4">
      <h3 className="text-md mb-2 font-medium underline">Yayın Durumu</h3>
        <button
          className={`w-full py-1.5 text-white rounded-lg text-md font-medium ${
            projectData?.isPublished
              ? "bg-red-500 hover:bg-red-600"
              : "bg-green-500 hover:bg-green-600"
          }`}
          onClick={togglePublishStatus}
        >
          {projectData?.isPublished ? "Projeyi Yayından Kaldır" : "Projeyi Yayına Al"}
        </button>
      </div>
      <div className="w-full h-0.5 border border-gray-200 my-4"></div>

      {/* Proje Linki */}
      <div className="mb-4">
      <h3 className="text-md mb-2 font-medium underline">Yayın Linki</h3>
        <p className="text-sm text-gray-600">
          Yayınlanacak proje bağlantısı aşağıda verilmiştir. Bu bağlantıyı
          başkalarıyla paylaşabilirsiniz.
        </p>
        <div className="mt-3 flex items-center gap-3 p-2 border border-gray-300 rounded-lg">
          <span className="text-sm text-gray-700 flex-grow truncate">{`${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData?.projectUuid}/publish`}</span>
          <div
            className="text-blue-500 hover:text-blue-700 text-sm cursor-pointer"
            onClick={handleCopyToClipboard}
          >
            <Icon name="copy-icon" size={20} />
          </div>
        </div>
      </div>

      {/* Linke Git Butonu */}
      <button
        className="w-full py-2 bg-[#0D92F4] text-white rounded-lg text-md font-medium hover:bg-[#0c82d2]"
        onClick={handleNavigateToLink}
      >
        Linke Git
      </button>
    </div>
  );
};

export default PublishProjectModal;
