import { useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
import { useEffect, useState } from "react";

function NotFound() {
  const navigate = useNavigate();
  const [isLogin,setISLogin] = useState(false)

  const handleNavigateHome = () => {
    navigate("/");
  };

  const handleNavigateLogin = () => {
    navigate("/auth");

  }

  useEffect(() => {
    const isLogin = Boolean(localStorage.getItem('accessToken'))
    setISLogin(isLogin)
  }, []);

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <div className="flex flex-col items-center">
        <img src="/assets/images/not-found.webp" alt="not found illustration" />
        <div>
          <h1 className="-mt-16 text-2xl text-bold text-gray-400">
            Aradığınız sayfa bulunamadı!
          </h1>
          <div className="flex justify-center mt-6">
            <Button
              label={isLogin ? 'Ana Sayfaya Dön' : 'Giriş Sayfasına Git'}
              className="bg-gray-600"
              textClassName="text-white"
              onClick={isLogin ? handleNavigateHome : handleNavigateLogin}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
