import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/users`;

export const searchUsers = async (query: string, limit?: number): Promise<{ username: string }[]> => {
  const accessToken = localStorage.getItem("accessToken");
  
  if (!accessToken) {
    throw new Error("401");
  }

  try {
    const response = await axios.get(`${API_URL}/search`, {
      params: {
        query,
        limit
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        throw new Error("403");
      } else if (error.response?.status === 404) {
        return [];
      }
      throw new Error("500");
    }
    throw error;
  }
};

