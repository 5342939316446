import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import {
  Workspace,
  WorkspaceContextType,
  WorkspaceProject,
  WorkspaceRole,
  WorkspaceUser,
} from "../types/ContextTypes";

// Context oluşturuluyor
const WorkspaceContext = createContext<WorkspaceContextType | null>(null);

export const WorkspaceProvider = ({ children }: { children: ReactNode }) => {
  const [ownedWorkspaces, setOwnedWorkspaces] = useState<Workspace[]>([]);
  const [memberWorkspaces, setMemberWorkspaces] = useState<Workspace[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null
  );
  const [workspaceMyRole, setWorkspaceMyRole] = useState<WorkspaceRole>(null);
  const [workspaceUsers, setWorkspaceUsers] = useState<WorkspaceUser[]>([]);
  const [selectedProject, setSelectedProject] = useState<WorkspaceProject | null>(null);

  // Kullanıcıları role göre sırala
  useEffect(() => {
    const sortedUsers = [...workspaceUsers].sort((a, b) => {
      const rolePriority = {
        owner: 1,
        admin: 2,
        member: 3
      };
      return rolePriority[a.role] - rolePriority[b.role];
    });
    
    if (JSON.stringify(sortedUsers) !== JSON.stringify(workspaceUsers)) {
      setWorkspaceUsers(sortedUsers);
    }
  }, [workspaceUsers.length]);




  return (
    <WorkspaceContext.Provider
      value={{
        ownedWorkspaces,
        setOwnedWorkspaces,
        memberWorkspaces,
        setMemberWorkspaces,
        selectedWorkspace,
        setSelectedWorkspace,
        workspaceMyRole,
        setWorkspaceMyRole,
        workspaceUsers,
        setWorkspaceUsers,
        selectedProject,
        setSelectedProject,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

// Custom hook
export const useWorkspaceContext = (): WorkspaceContextType => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error(
      "useWorkspaceContext must be used within a WorkspaceProvider"
    );
  }
  return context;
};
