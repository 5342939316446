import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSlideContext } from "../contexts/SlideContext";
import { getProjectById, getPublicProjectById } from "../api/project";
import SlideLayout from "../components/SlideCanvas/SlideLayout";
import { useMediaQuery } from "react-responsive";
import Icon from "../components/common/Icon";
import Button from "../components/common/Button";

const ProjectPublish = () => {
  const { projectUuid } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const {
    projectData,
    setProjectData,
    slides,
    setSlides,
    selectedSlide,
    setSelectedSlide,
    canvasState,
    setCanvasState,
  } = useSlideContext();

  const navigate = useNavigate();

  useEffect(() => {

    const fetchProjectData = async () => {
      
      if (!projectUuid) return;
      
      try {
        const token = localStorage.getItem("accessToken"); // Token kontrolü

        let project;

        if (token) {
          // Token varsa kullanıcı giriş yapmış, private API'yi kullan
          project = await getProjectById(projectUuid);
        } else {
          // Token yoksa public API'yi kullan
          project = await getPublicProjectById(projectUuid);
        }

        setProjectData(project);

        if (project.data?.slides) {
          setSlides(project.data.slides);
          setSelectedSlide(project.data.slides[0] || null); // İlk slaytı seç
          
        }
      } catch (error) {
        console.error("Proje verisi alınamadı:", error);
      }
    };

    fetchProjectData();
  }, [projectUuid]);

  useEffect(() => {
    // Mobilde ise screenType'ı "mobile", değilse "desktop" yap
    if (isMobile) {
      setCanvasState((prev) => ({ ...prev, screenType: "mobile" }));
    } else {
      setCanvasState((prev) => ({ ...prev, screenType: "desktop" }));
    }
  }, [isMobile, canvasState.screenType]);

  const currentSlideIndex = slides.findIndex(
    (slide) => slide.id === selectedSlide?.id
  );

  const isFirstSlide = currentSlideIndex === 0;
  const isLastSlide = currentSlideIndex === slides.length - 1;

  const goToPreviousSlide = () => {
    if (isFirstSlide) return;
    setSelectedSlide(slides[currentSlideIndex - 1]);
  };

  const goToNextSlide = () => {
    if (isLastSlide) return;
    setSelectedSlide(slides[currentSlideIndex + 1]);
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowLeft":
          goToPreviousSlide();
          break;
        case "ArrowDown":
        case "ArrowRight":
          goToNextSlide();
          break;
        default:
          break;
      }
    };

    // Event listener ekle
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: Component unmount olduğunda event listener'ı kaldır
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [goToPreviousSlide, goToNextSlide]);

  if (!projectData || !slides || slides.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <div className="flex flex-col items-center">
          <img
            src="/assets/images/not-publish.avif"
            alt="not publish illustration"
          />
          <div>
            <h1 className=" text-2xl text-bold text-gray-400">
              Proje yok veya yayında değil!
            </h1>
            <div className="flex justify-center mt-6">
              <Button
                label="Ana Sayfaya Dön"
                className="bg-gray-600"
                textClassName="text-white"
                onClick={handleNavigateHome}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`w-screen h-screen flex flex-col items-center justify-center bg-gray-100`}
    >

        <div
          className={`scrollbar overflow-auto  relative ${
            isMobile ? "w-full h-full" : "h-[calc(100%-64px)] w-[calc(100%-64px)]"
          } border bg-white border-gray-200`}
        >
          <SlideLayout readonly={true} isPlaceholderVisible={false}/>

          <div className={`fixed z-50 ${isMobile ? "right-4 bottom-4" : "right-12 bottom-12"}`}>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setCanvasState(prev => ({ ...prev, isVisibleOrderIndicator: !prev.isVisibleOrderIndicator }))}
                className="px-3 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
              >
                <Icon 
                  name={canvasState.isVisibleOrderIndicator ? "visibility-on-icon" : "visibility-off-icon"} 
                  size={24} 
                  className="text-white" 
                />
              </button>
              <button
                onClick={goToPreviousSlide}
                className="px-5 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
              >
                <Icon name="arrow-up-icon" size={24} className="text-white" />
              </button>
              <button
                onClick={goToNextSlide}
                className="px-5 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
              >
                <Icon name="arrow-down-icon" size={24} className="text-white" />
              </button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ProjectPublish;
