import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSlideContext } from "../contexts/SlideContext";
import { getProjectById } from "../api/project";
import SlideLayout from "../components/SlideCanvas/SlideLayout";
import Icon from "../components/common/Icon";

const ProjectPreview = () => {
  const { projectUuid } = useParams();
  const navigate = useNavigate();
  const {
    projectData,
    setProjectData,
    slides,
    setSlides,
    selectedSlide,
    setSelectedSlide,
    canvasState,
    toggleScreenType,
    setCanvasState,
  } = useSlideContext();

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!projectUuid) return;
  
      try {
        const project = await getProjectById(projectUuid);
  
        setProjectData(project);
  
        if (project.data?.slides) {
          setSlides(project.data.slides);
          setSelectedSlide(project.data.slides[0] || null); // İlk slaytı seç
        }
      } catch (error: any) {
        if (error.message === "401") {
          console.error("Oturum açılmamış:", error);
          navigate("/login-required"); // Giriş yapılması gerekiyor sayfasına yönlendir
        } else if (error.message === "403") {
          console.error("Yetkisiz erişim:", error);
          navigate("/forbidden"); // Yetkisiz erişim sayfasına yönlendir
        } else if (error.message === "404") {
          console.error("Proje bulunamadı:", error);
          navigate("/not-found"); // Proje bulunamadı sayfasına yönlendir
        } else {
          console.error("Beklenmeyen bir hata oluştu:", error);
          navigate("/error"); // Genel hata sayfasına yönlendir
        }
      }
    };
  
    fetchProjectData();
  }, [projectUuid]);
  
  

  

  const goToPreviousSlide = () => {
    if (!selectedSlide || slides.length === 0) return;

    const currentIndex = slides.findIndex(
      (slide) => slide.id === selectedSlide.id
    );

    if (currentIndex > 0) {
      setSelectedSlide(slides[currentIndex - 1]);
    }
  };

  const goToNextSlide = () => {
    if (!selectedSlide || slides.length === 0) return;

    const currentIndex = slides.findIndex(
      (slide) => slide.id === selectedSlide.id
    );

    if (currentIndex < slides.length - 1) {
      setSelectedSlide(slides[currentIndex + 1]);
    }
  };

  const restartPresentation = () => {
    if (slides.length > 0) {
      setSelectedSlide(slides[0]); // İlk slayta dön
    }
  };

  const navigateToEdit = () => {
    if (projectData) {
      navigate(
        `/projects/${projectUuid}/edit`
      );
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowLeft":
          goToPreviousSlide();
          break;
        case "ArrowDown":
        case "ArrowRight":
          goToNextSlide();
          break;
        default:
          break;
      }
    };
  
    // Event listener ekle
    window.addEventListener("keydown", handleKeyDown);
  
    // Cleanup: Component unmount olduğunda event listener'ı kaldır
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [goToPreviousSlide, goToNextSlide]);

  if (!projectData || !slides || slides.length === 0) {
    return <div>Proje verisi yükleniyor...</div>;
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-gray-100">
      <Header
        toggleScreenType={toggleScreenType}
        restartPresentation={restartPresentation}
        navigateToEdit={navigateToEdit}
      />
      <div
        className={`scrollbar overflow-auto relative ${
          canvasState.screenType === "mobile" ? "w-[420px]  h-[600px]" : "h-[90%] w-[calc(100%-64px)]"
        } border bg-white border-gray-200`}
      >
        <SlideLayout readonly={true} isPlaceholderVisible={false}/>
        <div className={`fixed z-50 ${canvasState.screenType === "mobile" ? "right-4 bottom-4" : "right-12 bottom-12"}`}>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setCanvasState(prev => ({ ...prev, isVisibleOrderIndicator: !prev.isVisibleOrderIndicator }))}
              className="px-3 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
            >
              <Icon 
                name={canvasState.isVisibleOrderIndicator ? "visibility-on-icon" : "visibility-off-icon"} 
                size={24} 
                className="text-white" 
              />
            </button>
            <button
              onClick={goToPreviousSlide}
              className="px-5 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
            >
              <Icon name="arrow-up-icon" size={24} className="text-white" />
            </button>
            <button
              onClick={goToNextSlide}
              className="px-5 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
            >
              <Icon name="arrow-down-icon" size={24} className="text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = ({
  toggleScreenType,
  restartPresentation,
  navigateToEdit,
}: {
  toggleScreenType: () => void;
  restartPresentation: () => void;
  navigateToEdit: () => void;
}) => {
  const { canvasState } = useSlideContext();

  return (
    <header className="flex items-center justify-between w-[130px] h-[40px] p-4 bg-[#f7f7f6] shadow-md mb-5">
      <div
        className="flex items-center cursor-pointer"
        onClick={toggleScreenType}
      >
        <Icon name={`${canvasState.screenType}-icon`} size={24} />
      </div>
      <button onClick={restartPresentation} className="flex items-center">
        <Icon name="refresh-icon" size={20} />
      </button>
      <button onClick={navigateToEdit} className="flex items-center">
        <Icon name="close-icon" size={20} />
      </button>
    </header>
  );
};

export default ProjectPreview;
