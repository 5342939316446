import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import {
  CanvasState,
  SlideContextType,
  Slide,
  ProjectData,
  Permission,
} from "../types/ContextTypes";

const SlideContext = createContext<SlideContextType | null>(null);

function SlideProvider({ children }: { children: ReactNode }) {
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [projectPermissions, setProjectPermissions] = useState<Permission[]>(
    []
  );

  const [canvasState, setCanvasState] = useState<CanvasState>({
    screenType: "desktop",
    contentMode: "presentation",
    isVisibleOrderIndicator: true,
    isImagePreviewVisible: false,
    isCodePreviewVisible: false
  });
  const [autoSave,setAutoSave] = useState<Boolean>(true)

  const [slides, setSlides] = useState<Slide[]>([]);
  const [selectedSlide, setSelectedSlide] = useState<Slide | null>(null);

  // Fonksiyonlar

  const toggleScreenType = () => {
    setCanvasState((prev) =>
      prev.screenType === "desktop"
        ? { ...prev, screenType: "mobile" }
        : { ...prev, screenType: "desktop" }
    );
  };

  const toggleImagePreview = (value: boolean) => {
    setCanvasState(prev => ({
      ...prev,
      isImagePreviewVisible: value
    }));
  };

  const toggleCodePreview = (value: boolean) => {
    setCanvasState(prev => ({
      ...prev,
      isCodePreviewVisible: value
    }));
  };

      // İlk yüklemede preview durumlarını ayarla
      useEffect(() => {
        if (selectedSlide) {
          // Resim/video varsa image preview'i aç
          if (selectedSlide.assetLink) {
            toggleImagePreview(true);
          } else {
            toggleImagePreview(false);
          }
    
          // Kod içeriği varsa code preview'i aç
          if (selectedSlide.code.content) {
            toggleCodePreview(true);
          } else {
            toggleCodePreview(false);
          }
        }
      }, [selectedSlide?.id]); // Slide değiştiğinde kontrol et

  return (
    <SlideContext.Provider
      value={{
        projectData,
        setProjectData,
        canvasState,
        setCanvasState,
        slides,
        setSlides,
        selectedSlide,
        setSelectedSlide,
        toggleScreenType,
        projectPermissions,
        setProjectPermissions,autoSave,setAutoSave,
        toggleImagePreview,
        toggleCodePreview
      }}
    >
      {children}
    </SlideContext.Provider>
  );
}

export const useSlideContext = (): SlideContextType => {
  const context = useContext(SlideContext);
  if (!context) {
    throw new Error("SlideContext must be used within a SlideProvider");
  }
  return context;
};

export default SlideProvider;
