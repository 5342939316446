import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { getMe, logout } from "../../api/auth";
import { toast } from "react-toastify";
import Icon from "../common/Icon";

const UserDisplay = () => {
  const { username, setUsername, setIsLogin } = useAuthContext();
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      toast.success("Başarıyla çıkış yapıldı.");
      setIsLogin(false);
      setShowTooltip(false);
      setTimeout(() => navigate("/auth"), 500);
    } catch (error) {
      console.error("Çıkış işlemi başarısız:", error);
      toast.error("Çıkış işlemi sırasında bir hata oluştu.");
    }
  };

  const fetchUser = async () => {
    try {
      const user = await getMe();
      setUsername(user.username);
    } catch (error) {
      console.error("Kullanıcı bilgileri alınamadı:", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/login-required");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="relative flex justify-end">
      <div className="flex items-center gap-2">
        <img
          src={`https://api.dicebear.com/7.x/identicon/svg?seed=${
            username || "guest"
          }`}
          alt="avatar"
          className="w-8 h-8 rounded-full"
        />

        <span
          className="text-md text-black underline underline-offset-4 cursor-pointer"
          onClick={() => {
            if (username) setShowTooltip((prev) => !prev);
            else navigate("/login-required");
          }}
        >
          {username ? username : "Giriş Yap"}
        </span>
      </div>

      {showTooltip && username && (
        <div className="absolute top-10 right-0 bg-white border border-gray-200 rounded-lg shadow-lg py-1 w-[180px] z-50">
          <div className="px-4 py-2 hover:bg-gray-50 cursor-pointer flex items-center gap-2">
            <Icon name="person-icon" size={20} />
            <span className="text-sm text-gray-800">Profilim</span>
          </div>
          <div className="px-4 py-2 hover:bg-gray-50 cursor-pointer flex items-center gap-2">
            <Icon name="settings-icon" size={20} />
            <span className="text-sm text-gray-800">Ayarlar</span>
          </div>
          <div className="border-t border-gray-200 my-1" />
          <div 
            className="px-4 py-2 hover:bg-gray-50 cursor-pointer flex items-center gap-2 text-red-600"
            onClick={handleLogout}
          >
            <Icon name="logout-icon" size={20} className="fill-red-600" />
            <span className="text-sm">Çıkış Yap</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDisplay;
