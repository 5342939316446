import { Reorder } from "framer-motion";
import SlideItem from "./SlideItem";
import Button from "../common/Button";
import { useSlideContext } from "../../contexts/SlideContext";
import { ProjectData, Slide } from "../../types/ContextTypes";

const SlidesBar = () => {
  const {
    slides,
    setSlides,
    projectData,
    setProjectData,
    projectPermissions,
    setSelectedSlide,
  } = useSlideContext();

  const handleReorder = (newOrder: Slide[]) => {
    const reorderedSlides = newOrder.map((slide, index) => ({
      ...slide,
      order: index + 1, // Yeni sıralamaya göre order ataması
    }));

    const updatedProjectData: ProjectData = {
      ...projectData!,
      data: {
        ...projectData!.data,
        slides: reorderedSlides, // slides'ı data altında güncelliyoruz
      },
    };

    setSlides(reorderedSlides);
    setProjectData(updatedProjectData);
  };

  const addNewSlide = () => {
    const maxId =
      slides.length > 0 ? Math.max(...slides.map((slide) => slide.id)) : 0;

    const newSlide: Slide = {
      id: maxId + 1,
      order: slides.length + 1,
      assetLink: "",
      code: {
        content: "",
        language: "javascript",
        theme:'light'
      },
      settings: {
        design: {
          titleSize: "medium",
          titleColor: "#333333",
          descSize: "small",
          descColor: "#666666",
          titleAlign: "align-center",
          descAlign: "align-center"
        },
        layout: {
          mobile: "layout1",
          desktop: "layout1",
        },
      },
      template: {
        type: "text",
        iconName: "text-icon",
        iconTitle: "Metin",
        templateContent: {
          title: "",
          desc: "",
        },
      },
    };

    const updatedSlides = [...slides, newSlide];

    const updatedProjectData: ProjectData = {
      ...projectData!,
      data: {
        slides: updatedSlides,
      },
    };

    setSlides(updatedSlides);
    setProjectData(updatedProjectData);
    setSelectedSlide(newSlide);
  };

  return (
    <aside className="flex flex-col items-center justify-between basis-[15%] h-full pb-6 bg-[#f7f7f6] rounded-xl shadow-md ">
      <div className="w-full h-[90%] scrollbar overflow-auto p-4">
        <Reorder.Group
          axis="y"
          values={slides}
          onReorder={handleReorder}
          layoutScroll /* Önemli: Scrollable alanlarda düzgün sürükleme sağlar */
          className="flex flex-col gap-2 h-full"
        >
          {slides.map((slide) => (
            <Reorder.Item key={slide.id} value={slide}>
              <SlideItem
                order={slide.order}
                title={slide.template.templateContent.title}
                iconName={slide.template.iconName}
                slideId={slide.id}
              />
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </div>
      <div className="">
        {projectPermissions.includes("edit") && (
          <Button
            iconName="add-icon"
            iconClassName="text-black"
            label="Slayt Ekle"
            className="bg-white"
            onClick={addNewSlide}
          />
        )}
      </div>
    </aside>
  );
};

export default SlidesBar;
