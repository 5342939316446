import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext";
import { useUIContext } from "../../contexts/UIContext";
import { refreshProjectUuid, updateProject } from "../../api/project";
import Icon from "../common/Icon";

const UpdateProjectModal = () => {
  const {
    selectedProject,
    setSelectedProject,
    setOwnedWorkspaces,
    setMemberWorkspaces,
    selectedWorkspace,
  } = useWorkspaceContext();
  const { hideAllModals } = useUIContext();

  const [projectName, setProjectName] = useState<string>("");
  const [isPublished, setIsPublished] = useState<boolean>(false);

  // selectedProject'den state'leri yükle
  useEffect(() => {
    if (selectedProject) {
      setProjectName(selectedProject.projectName);
      setIsPublished(selectedProject.isPublished);
    }
  }, [selectedProject]);

  const handleUpdateProject = async () => {
    if (!selectedProject) {
      toast.error("Seçili bir proje bulunamadı.");
      return;
    }
  
    if (!projectName.trim()) {
      toast.error("Proje adı boş olamaz.");
      return;
    }
  
    try {
      const updatedProjectData = {
        title: projectName.trim(),
        isPublished,
      };
  
      // API üzerinden projeyi güncelle
      const updatedProject = await updateProject(
        selectedProject.uuid,
        updatedProjectData
      );
  
      toast.success("Proje başarıyla güncellendi!");
  
      // UI Güncellemesi
      setOwnedWorkspaces((prev) =>
        prev.map((workspace) =>
          workspace.workspaceId === selectedWorkspace?.workspaceId
            ? {
                ...workspace,
                workspaceProjects: workspace.workspaceProjects.map((proj) =>
                  proj.uuid === selectedProject.uuid
                    ? {
                        ...proj,
                        projectName: updatedProject.title,
                        isPublished: updatedProject.isPublished,
                      }
                    : proj
                ),
              }
            : workspace
        )
      );
  
      setMemberWorkspaces((prev) =>
        prev.map((workspace) =>
          workspace.workspaceId === selectedWorkspace?.workspaceId
            ? {
                ...workspace,
                workspaceProjects: workspace.workspaceProjects.map((proj) =>
                  proj.uuid === selectedProject.uuid
                    ? {
                        ...proj,
                        projectName: updatedProject.title,
                        isPublished: updatedProject.isPublished,
                      }
                    : proj
                ),
              }
            : workspace
        )
      );
  
      // `selectedProject` state'ini güncelle
      setSelectedProject((prev) =>
        prev
          ? {
              ...prev,
              projectName: updatedProject.title,
              isPublished: updatedProject.isPublished,
            }
          : null
      );
  
      hideAllModals(); // Modalı kapat
    } catch (error) {
      console.error("Proje güncelleme hatası:", error);
      toast.error("Proje güncellenirken bir hata oluştu.");
    }
  };
  

  const handleRefreshUuid = async () => {
    if (!selectedProject) {
      toast.error("Seçili bir proje bulunamadı.");
      return;
    }

    try {
      const response = await refreshProjectUuid(selectedProject.uuid);
      const newUuid = response.newUuid;

      toast.success("Proje UUID'si başarıyla yenilendi!");

      // UI Güncellemesi
      setOwnedWorkspaces((prev) =>
        prev.map((workspace) =>
          workspace.workspaceId === selectedWorkspace?.workspaceId
            ? {
                ...workspace,
                workspaceProjects: workspace.workspaceProjects.map((proj) =>
                  proj.uuid === selectedProject.uuid
                    ? { ...proj, uuid: newUuid }
                    : proj
              ),
            }
          : workspace
      ));

      setMemberWorkspaces((prev) =>
        prev.map((workspace) =>
          workspace.workspaceId === selectedWorkspace?.workspaceId
            ? {
                ...workspace,
                workspaceProjects: workspace.workspaceProjects.map((proj) =>
                  proj.uuid === selectedProject.uuid
                    ? { ...proj, uuid: newUuid }
                    : proj
              ),
            }
          : workspace
      ));
      setSelectedProject((prev) =>
        prev ? { ...prev, uuid: newUuid } : null
      );
      
    } catch (error) {
      console.error("UUID yenileme hatası:", error);
      toast.error("UUID yenilenirken bir hata oluştu.");
    }
  };

  if (!selectedProject) {
    return (
      <div className="relative w-[650px] h-auto bg-white rounded-xl pt-8">
        <div className="flex justify-between items-center pl-8 pr-4">
          <h2 className="text-gray-400">Proje Güncelle</h2>
          <div onClick={hideAllModals}>
            <Icon
              name="close-icon"
              className="fill-gray-400 cursor-pointer"
              size={24}
            />
          </div>
        </div>
        <div className="w-full h-0.5 border border-gray-200 my-4"></div>
        <p className="text-center text-gray-600">
          Seçili bir proje bulunamadı.
        </p>
      </div>
    );
  }

  const projectLink = `${process.env.REACT_APP_DOMAIN_URL}/projects/${selectedProject.uuid}/publish`;

  return (
    <div className="relative w-96 h-auto bg-white rounded-xl py-8">
      <div className="flex justify-between items-center px-6">
        <h2 className="text-gray-400">Proje Güncelle</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 my-4"></div>

      <div className="px-6 flex flex-col gap-4">
        <div>
          <h3 className="text-md mb-2 font-medium underline">Proje Adı</h3>
          <input
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            placeholder="Proje Adı"
            className="w-full h-10 p-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div>
          <h3 className="text-md mb-2 font-medium underline">Yayın Durumu</h3>
          <input
            type="checkbox"
            checked={isPublished}
            onChange={(e) => setIsPublished(e.target.checked)}
          />
        </div>

        <div>
          <h3 className="text-md mb-2 font-medium underline">Yayın Linki</h3>
          <div className="flex items-center gap-4">
            <span className="text-gray-600">
              {projectLink.length > 30
                ? `${projectLink.substring(0, 30)}...`
                : projectLink}
            </span>
            <div className="flex items-center gap-2">
              <div
                onClick={() => {
                  navigator.clipboard.writeText(projectLink);
                  toast.info("Link kopyalandı!");
                }}
                className="cursor-pointer"
              >
                <Icon
                  name="copy-icon"
                  size={18}
                  className="text-blue-500 hover:fill-blue-700"
                />
              </div>
              <div onClick={handleRefreshUuid}>
                <Icon
                  name="refresh-icon"
                  size={18}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <button
            className="w-40 py-2 bg-black text-white rounded-lg"
            onClick={handleUpdateProject}
          >
            Güncelle
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateProjectModal;
